function getGeometryTypeFromGeojson(geometry) {
    /*
    Returns the short-form lowercase geometry (eg "polygon"), or null,
    for @param geometry, which is a CamelCased Geojson-style geometry name

    Where this function is used to test locations?.locations?.[0]?.geometry?.type
    the rationale is helpful to understand:

    React requires a single source of truth for every state,
    but we have at least two for geometryType:
    - metadata as selections.outputOptions...geometryType
    - geodata as locations.locations...geometry.type

    Thus selections...geometryType only advisory for selections logic,
    while actual maps are drawn from actual locations,
    so must use the geometry.type found in locations,
    which are assigned based on the first value found, 
    since locations have no metadata
    */
    switch (geometry) {
        case "Point":  // GeoJson type name
            return "point";  // Metadata reference
        case "Polygon":
        case "MultiPolygon":
            return "polygon";
        case "LineString":
        case "MultiLineString":
            return "line";
        default:
          return null;
      }
}

export { getGeometryTypeFromGeojson };
