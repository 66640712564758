import themes from "themes.json";
import { ValueField } from "utils/enums";

function roundToThreeSigFigs(num) {
    return Number.parseFloat(num.toPrecision(3));
}

function getThematicOptions(data = null, thematic = null, valueField = ValueField.Value.value, 
    geometryType = null, outputOptions = {}) {
    /*
    Returns dictionary of options that defines map object styles and colours,
    .range is an array of dictionaries containing lessthan (numeric) and style (CSS value)
    .pathOptions are Leaflet path options: https://leafletjs.com/reference.html#path

    @param data is an array of location dicts
    @param thematic is the current themes.regionShading
    @param valueField is a Valuefield .value
    @param geometryType is a short-form lowercase geometry (eg "polygon")
    @param outputOptions is the selections...outputOptions of the current output
    */
    const options = { 
        pathOptions: {},
        showResolution: outputOptions?.fixedSelectors?.includes('resolution') ? true : false,
        idPrefix: outputOptions?.idDisplayPrefix ?? 'Ref'
    };

    if (Object.is(null, data) || data.length === 0 || Object.is(null, thematic) ||
            (!(themes.hasOwnProperty(thematic)) && thematic !== "invisible")) return options;

    options.pathOptions.weight = (geometryType === "line") ? 5 : 1; // Line pixel width
    if (geometryType !== "point") options.pathOptions.lineJoin = "round";
    if (geometryType !== "line") options.pathOptions.color = 
        themes[thematic]?.themeColors?.secondary ?? "grey"; // Border line colour

    switch (themes[thematic]?.type) {
        case "opacity":
            if (geometryType !== "line") {
                options.stylename = "fillOpacity";
                options.pathOptions.fillColor = themes[thematic].base;
            } else {
                options.stylename = "opacity";
                options.pathOptions.color = themes[thematic].base;
            }
            break;
        case "color":
            if (geometryType !== "line") {
                options.stylename = "fillColor";
                options.pathOptions.fillOpacity = themes[thematic].base;
            } else {
                options.stylename = "color";
                options.pathOptions.opacity = themes[thematic].base;
            }
            break;
        default:
            if (thematic === "invisible") { 
                options.stylename = "fillColor";
                options.pathOptions.color = "transparent";
                options.pathOptions.fillOpacity = 0;
            } else {
                options.stylename = "color";
                options.pathOptions.color = "grey";
            }
    }

    if (outputOptions?.dataType?.hidden === false) {
        const minValue = roundToThreeSigFigs(data
            .map((region) => region[valueField])
            .reduce((a, b) => (a < b ? a : b), 10e9));
        const maxValue = roundToThreeSigFigs(data
            .map((region) => region[valueField])
            .reduce((a, b) => (a < b ? b : a), 0));
        const valueDiff = maxValue - minValue;
        const metaRangeLen = themes[thematic].range.length;
        const step = 1 / metaRangeLen;
        const range = []; // Array of {lessthan, style}

        themes[thematic].range.forEach((band, index) => {
            const rawValue = themes[thematic].log > 0
                ? ((Math.pow(themes[thematic].log, step * (index + 1)) - 1) /
                    (themes[thematic].log - 1)) * valueDiff + minValue
                : (step * valueDiff * (index + 1) + minValue);
            range.push({
                lessthan: roundToThreeSigFigs(rawValue),
                style: band,
            });
        });
        options.range = range;
        options.minValue = minValue;
        options.valueField = valueField;
        options.displayName = outputOptions?.valueDisplayName;
        options.unitPrefix = outputOptions?.valueDisplayUnits?.prefix;
        options.unitSuffix = outputOptions?.valueDisplayUnits?.suffix;
    } else if (thematic === "invisible") {
        options.valueField = valueField;
        options.displayName = outputOptions?.valueDisplayName;
        options.unitPrefix = outputOptions?.valueDisplayUnits?.prefix;
        options.unitSuffix = outputOptions?.valueDisplayUnits?.suffix;
        return options;
     }
    else {
        options.pathOptions[options.stylename] = 
            themes[thematic].range[(themes[thematic].range.length - 1)];
    }

    return options;
}

export { getThematicOptions };