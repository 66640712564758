import React from "react";

import styled from "styled-components";

import { VisualiserContext } from "pages/Visualiser/context";
import { SELECTIONS_ACTIONS } from "pages/Visualiser/reducers";
import BaseSelector from "./BaseSelector";
import Introduction from "../Informational/Introduction";

const Wrapper = styled.div`
  width: 100%;
  margin: 0.8em 0;
`;

export default function FixedSelectors() {
  const { state } = React.useContext(VisualiserContext);
  const { outputOptions, output } = state?.selections ?? {};

  const name = "output";
  const accessor = "selections.output";
  const action = SELECTIONS_ACTIONS.SET_OUTPUT;
  const options = outputOptions
    ? outputOptions.map((option) => {
      return {
        text: option.outputDisplayName,
        value: option.optionName,
      };
    })
    : [];
  options.sort((a, b) => a.text.localeCompare(b.text));
  options.unshift({ text: "Select an output...", value: null });

  return (
    <Wrapper>
      <h4 className="text-left h6">Analyse</h4>
      {output ? null : <Introduction title="introSecondPart"/> }
      <BaseSelector
        key={name}
        options={options}
        action={action}
        accessor={accessor}
      />
    </Wrapper>
  );
}
