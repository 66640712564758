import styled from "styled-components";
import Selected from "./Selected"
import InfoSelector from "./InfoSelector";

const OuterWrapper = styled.div`
  flex-grow: 2;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  padding: 0.5rem;
`;

export default function Informational() {
  return (
    <OuterWrapper>
      <Wrapper>
        <InfoSelector />
        <Selected />
      </Wrapper>
    </OuterWrapper>
  );
}
