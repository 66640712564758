import React, { useEffect, useState } from "react";

import { MapContainer, TileLayer, useMapEvents } from "react-leaflet";

import { INTERACTIONS_ACTIONS } from "pages/Visualiser/reducers";
import { PAGES } from "pages/config";
import { getGeometryTypeFromGeojson } from "utils/geometry";
import { getThematicOptions } from "utils/theming";
import { VisualiserContext } from "../context";
import Legend from "./Legend";
import { LocationFilterer } from "./filterer";
import { ObjectRenderer, UpdateMapCentre } from "./renderer";

function Map() {
  /*
  Main map area container, rendered from bottom to top

  Technically a Leaflet MapContainer, but only the TileLayer natively uses Leaflet
  The remainder of objects are rendered by React and merely placed on the Leaflet canvas

  @param position is the Leaflet position (lat, lng) default is WGS 84
  */
  const { state, index } = React.useContext(VisualiserContext);
  const [baseCoord, setBaseCoord] = useState(PAGES[index].position);
  const [previousCoord, setPreviousCoord] = useState("");
  const zoom = state?.interactions?.zoom ?? 8;
  const laBaseLayer = useBaseLayer(
    "allLas",
    {
      pathOptions: {
        weight: 1, // Line pixel width
        color: "grey", // Greyscale, since thematic colors render atop
        fill: false,
        lineJoin: "round",
        opacity: 1,
      },
    },
    7
  ); // Masked at zoom level
  const roadBaseLayer = useBaseLayer(
    "allRoads",
    {
      pathOptions: {
        weight: 5, // Line pixel width
        color: "grey", // Greyscale, since thematic colors render atop
        lineJoin: "round",
        opacity: 0.2,
      },
      idPrefix: "Road",
    },
    9
  ); // Masked at zoom level
  const mapObjects = useRenderMapObjects();

  useEffect(() => {
    setPreviousCoord(baseCoord);
    if (baseCoord !== PAGES[index].position)
      setBaseCoord(PAGES[index].position);
  }, [index, baseCoord]);

  return (
    <div className="h-100 w-100" style={{ backgroundColor: "#444" }}>
      <MapContainer
        center={baseCoord}
        zoom={zoom}
        style={{ width: "100%", height: "100%" }}
        preferCanvas={true}
      >
        <TileLayer
          attribution='Built by <a href="https://transportforthenorth.com/" 
          title="Transport for the North">TfN</a></a> <span aria-hidden="true">|</span> Basemap 
          &copy; <a href="http://osm.org/copyright">OpenStreetMap</a> 
          contributors <span aria-hidden="true">|</span> Boundaries &amp; roads <abbr title="Office for National Statistics">ONS</abbr> 
          &amp; <abbr title="Ordnance Survey">OS</abbr> <a 
          href="https://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/" 
          title="Open Government Licence">
          via OGL v3</a> containing data &copy; Crown copyright &amp; 
          database right 2024'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {laBaseLayer}
        {roadBaseLayer}
        {mapObjects}
        <Legend />
        <Events />
        <UpdateMapCentre location={baseCoord} previous={previousCoord} />
      </MapContainer>
    </div>
  );
}

function Events() {
  /*
  Empty layer that monitors map position changes
  Currently only zoom is stored, but this could be extended for other variables
  */
  const { dispatch } = React.useContext(VisualiserContext);
  const mapEvents = useMapEvents({
    zoomend: () => {
      dispatch({
        type: INTERACTIONS_ACTIONS.SET_ZOOM,
        payload: mapEvents.getZoom(),
      });
    },
  });
  return null;
}

function useBaseLayer(target, thematicOptions, maskAtZoom = 0) {
  /*
  Renders an empty basemap for @param target, a state.globals locations dataset (allLas, allRoads)
  with @param thematicOptions, a dictionary structured as created by getThematicOptions()
  and @param maskAtZoom which is the zoom level above which the opacity will be set to zero

  The purpose is both visual and allows filtering for districts/roads not otherwise displayed,
  which is especially important for complex MSOA filtering
  */
  const { state, dispatch } = React.useContext(VisualiserContext);
  const allData = React.useMemo(
    () => state.globals[target] ?? {},
    [state, target]
  );
  const zoom = state?.interactions?.zoom ?? 0;
  if (zoom < maskAtZoom) thematicOptions.pathOptions.opacity = 0; // Masked
  if (zoom === maskAtZoom)
    // Fades in (half opacity)
    thematicOptions.pathOptions.opacity =
      thematicOptions.pathOptions.opacity / 2;

  return React.useMemo(() => {
    const renderer = new ObjectRenderer(dispatch, thematicOptions);
    const allDataArray = Object.values(allData);
    return renderer.render(allDataArray);
  }, [dispatch, allData, thematicOptions]);
}

function useRenderMapObjects() {
  /*
  Renders the map displaying data, for all geometry types
  */
  const { state, dispatch } = React.useContext(VisualiserContext);
  const { selections, themes, locations } = state ?? {};

  return React.useMemo(() => {
    if (selections?.pauseMapRender === true) return null;
    const locationsList = locations?.locations ?? [];
    if (locationsList.length === 0) return null;
    const outputOptions =
      selections?.outputOptions?.filter(
        (option) => option.optionName === selections?.output
      )?.[0] ?? null;
    const geometryType = getGeometryTypeFromGeojson(
      locations?.locations?.[0]?.geometry?.type
    );
    if (geometryType !== outputOptions?.geometryType) return null;
    // No synergy between locations and metadata (yet)

    const thematic = themes?.regionShading ?? null;

    const valueField = selections?.valueField ?? null;
    const filterer = new LocationFilterer(selections);
    const filteredLocations = filterer.filter(locationsList);
    const thematicOptions = getThematicOptions(
      filteredLocations,
      thematic,
      valueField,
      geometryType,
      outputOptions
    );
    const renderer = new ObjectRenderer(dispatch, thematicOptions);
    const objects = renderer.render(filteredLocations);
    if (selections.filteredLocations !== null) {
      // selections.filteredLocations = null;
      const newSelections = { ...selections };
      const newOutputOptions = JSON.parse(JSON.stringify(outputOptions));
      newOutputOptions.dataType.hidden = true;
      newSelections.filteredLocations = null;
      const fullLocations = new LocationFilterer(newSelections).filter(locationsList);
      const invisbleLocations = fullLocations.filter(
        (location) => !filteredLocations.includes(location)
      );
      const invisibleThematicOptions = getThematicOptions(
        invisbleLocations,
        "invisible",
        valueField,
        geometryType,
        newOutputOptions
      );
      const invisibleRenderer = new ObjectRenderer(dispatch, invisibleThematicOptions);
      const invisibleObjects = invisibleRenderer.render(invisbleLocations);

      return objects.concat(invisibleObjects);
    }
    return objects;
  }, [dispatch, selections, themes, locations]);
}

export default React.memo(Map);
