import React from "react";

import { api } from "services";
import { purgeCache } from "services/api/Geodata";
import { GLOBALS_ACTIONS, SELECTIONS_ACTIONS } from "../reducers";


export default function useOnAppStart(dispatch,apiPostFix) {
    /*
    Initialises the OutputOptions state in selectedOptions. OutputOptions
    are used to list the model outputs which can be selected for visualisation.
    */
    React.useEffect(() => {
        dispatch({ type: GLOBALS_ACTIONS.SET_IS_LOADING });

        Promise.all([
                loadOutputsMetadata(dispatch,apiPostFix)
                // Must complete before the geodata is called (in .then) 
                // because metadata defines valid geometry_id for geodata
            ])
            .then(() => {
                Promise.all([
                    loadLaMsoaMapping(dispatch, apiPostFix),
                    loadAllLas(dispatch, apiPostFix),
                    loadAllRoads(dispatch)
                ])
                .catch(() => { 
                    alert("Required data could not be obtained. Please try reloading the page.");
                })
                .finally(_ => {
                    dispatch({ type: GLOBALS_ACTIONS.SET_LOADING_FINISHED });
                });
            })
            .catch(() => { 
                alert("Required data could not be obtained. Please try reloading the page.");
            });
    }, [dispatch, apiPostFix]);
}

function loadOutputsMetadata(dispatch,apiPostFix) {
    return api.metadata.getAllMetadata(apiPostFix).then((allMetadata) => {
        purgeCache(allMetadata,apiPostFix);
        dispatch({
            type: GLOBALS_ACTIONS.SET_OUTPUTS_METADATA,
            payload: allMetadata
        });
        dispatch({
            type: SELECTIONS_ACTIONS.SET_OUTPUT_OPTIONS,
            payload: allMetadata
        });
    });
}

function loadLaMsoaMapping(dispatch,apiPostFix) {
    return api.geodata.getLaMsoaMapping(apiPostFix).then(laMsoaMapping => {
        dispatch({
            type: GLOBALS_ACTIONS.SET_LA_MSOA_MAPPING,
            payload: laMsoaMapping
        });
    });
}

function loadAllLas(dispatch, apiPostFix) {
    return api.geodata.getAllLas(apiPostFix).then(allLas => {
        dispatch({
            type: GLOBALS_ACTIONS.SET_ALL_LAS,
            payload: allLas
        });
    });
}

function loadAllRoads(dispatch) {
    return api.geodata.getAllRoads().then(allRoads => {
        dispatch({
            type: GLOBALS_ACTIONS.SET_ALL_ROADS,
            payload: allRoads
        });
    });
}
