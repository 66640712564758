import { default as ResultsService } from "./Results";
import { default as MetadataService } from "./Metadata";
import { GeodataService } from "./Geodata";

const api ={
  results: ResultsService,
  metadata: MetadataService,
  geodata: new GeodataService(),
};

export default api;
