import camelCase from "camelcase";

class LocationFilterer {
  constructor(selections) {
    this.selections = selections;
    this.conditions = [
      this.constructor.inFilteredLocations,
      this.constructor.matchYear,
      this.constructor.matchToSelected,
    ];
  }
  filter(locations) {
    if (!this.requiredOutputSelectionsAreSet()) return [];
    return this.conditions.reduce((remainingLocations, filter) => {
      if (!remainingLocations) return [];
      return filter(remainingLocations, this.selections);
    }, locations);
  }
  requiredOutputSelectionsAreSet() {
    const filterNames = getFilterableNames(this.selections);
    if (filterNames === false) return false;
    const { outputSelections } = this.selections ?? {outputSelections: {}};
    return filterNames.every(
      (name) => !Object.is(undefined, outputSelections[name])
    );
  }
  
  static matchToSelected(locations, selections) {
    const filterNames = getFilterableNames(selections);
    if (filterNames === false) return false;
    const { outputSelections } = selections ?? {outputSelections: {} };
    const requiredOutputSelections = Object.keys(outputSelections)
      .filter(key => filterNames.includes(key)).reduce((obj, key) => {
        obj[key] = outputSelections[key];
        return obj;
      }, {});
    return locations.filter((location) =>
      Object.entries(requiredOutputSelections).every(([name, value]) => {
        const camelCasedDataName = camelCase(name);
        return location?.[camelCasedDataName] === value;
      })
    );
  }

  static matchYear(locations, selections) {
    const { year } = selections ?? { year: null };
    if (Object.is(null, year)) return [];
    return locations.filter((location) => location?.year === year);
  }

  static inFilteredLocations(locations, selections) {
    const { filteredLocations } = selections ?? { filteredLocations: null };
    if (Object.is(null, filteredLocations)) return locations;
    return locations.filter((location) => {
      return (
        !Object.is(undefined, filteredLocations[location?.identifier]) ||
        !Object.is(undefined, filteredLocations[location?.parent_la])
      );
    });
  }
}

function getFilterableNames(selections) {
  /*
  @param selections is a VisualiserContext state.selections (this.selections)
  @return array of valid key names for filterables
  */
  const { output, outputOptions } = selections ?? {outputOptions: {}};
  const outputOption = outputOptions?.filter((option) => option.optionName === output)?.[0] ?? null;
  if (Object.is(null, outputOption)) return [];
  const filterables = outputOption?.filterables ?? [];
  return filterables.map((filterable) => filterable.filterableDataName);
}

export { getFilterableNames, LocationFilterer };
