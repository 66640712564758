import React from "react";

import objectPath from "object-path";
import styled from "styled-components";

import { DropdownSelector } from "components/Dropdown";
import { VisualiserContext } from "pages/Visualiser/context";

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0.1rem;
  border: 1px solid black;
  border-radius: 0.5rem;
`;
function BaseSelector({ options, action, defaultValue = null, aliaser = null, 
    setter = null, accessor = null }) {
  /*
  Base dropdown selector component
  @param options an array of dicts containing value and text
  @param action is a reducer reference, eg SELECTIONS_ACTIONS.SET_RESOLUTION
  @param accessor is a string path below state to the variable, eg "selections.resolution"
  */
  const { state, dispatch } = React.useContext(VisualiserContext);
  if (Object.is(null, accessor)) return null;

  const _setter = setter?.(dispatch, action) ?? defaultSetter(dispatch, action);
  const _state = objectPath.get(state, accessor);
  const _aliaser = aliaser ?? defaultAliaser(options);
  const value = _aliaser(_state);

  return (
    <Wrapper>
      {Object.is(null, options) ? null : (
        <DropdownSelector value={value} setter={_setter} options={options} defaultValue={defaultValue} />
      )}
    </Wrapper>
  );
}

function defaultAliaser(options) {
  return (value) => {
    const index = options.map((option) => option.value).indexOf(value);
    return options[index]?.text ?? "";
  };
}

function defaultSetter(dispatch, action) {
  return (value) => {
    if (value) dispatch({ type: action, payload: value });
  };
}

export default BaseSelector;
