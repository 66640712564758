import {
  CircleMarker,
  Polygon,
  Polyline,
  Tooltip,
  useMap,
} from "react-leaflet";

import { SELECTIONS_ACTIONS } from "../reducers";
import { getEnumNameAndDataMatchingValue, ValueField } from "utils/enums";
import { getDisplayNumeric } from "utils/formatting";
import { getGeometryTypeFromGeojson } from "utils/geometry";

class RendererError extends Error {
  constructor(errorMessage = "Renderer error") {
    super(errorMessage);
  }
}

class BaseRenderer {
  /*
  Child classes render a single object, which is placed on the map canvas
  Leaflet does not control these as layers, rather the objects remain under the control of React

  Called as ObjectRenderer(dispatch, options), where:
  @param dispatch is a visualiserContext-like dispatch
  @param options are those originally derived from getThematicOptions()

  Used as .render(location) where @param location is a locations-like dictionary
  which contains at least identifier and geometry
  */
  constructor(location = {}) {
    this.location = location;
  }
  render() {
    throw new RendererError("Render method not implemented");
  }
}

class NullRenderer {
  render() {
    return null;
  }
}

class PointRenderer extends BaseRenderer {
  static validate(location) {
    if (!location.hasOwnProperty("identifier"))
      throw new Error("Location must specify an identifier.");
  }

  render(dispatch, options) {
    const coordinates = this.location?.geometry?.coordinates;
    const lat = coordinates[0];
    const lon = coordinates[1];
    if (Object.is(null, lat) || Object.is(null, lon)) return null;

    const identifier = this?.location?.identifier ?? null;
    const center = [lat, lon];
    const pathOptions = getPathOptions(
      this.location?.[options.valueField],
      options
    );
    const tooltipHead = getTooltipHead(this.location, options);
    const tooltipValue = getTooltipValue(this.location, options);

    return (
      <CircleMarker key={identifier} center={center} pathOptions={pathOptions}>
        {Object.is(null, tooltipValue) ? (
          <Tooltip>{tooltipHead}</Tooltip>
        ) : (
          <Tooltip>
            {tooltipHead}
            <br />
            {tooltipValue}
          </Tooltip>
        )}
      </CircleMarker>
    );
  }
}

class PolygonAndLineRenderer extends BaseRenderer {
  static validate(location) {
    if (!location.hasOwnProperty("identifier"))
      throw new Error("Location must specify an identifier.");
  }

  render(dispatch, options) {
    this.constructor.validate(this.location);
    const key = this.location?.identifier;
    const positions = this.location?.geometry?.coordinates;
    const pathOptions = getPathOptions(
      this.location?.[options.valueField],
      options
    );
    const geometryType = getGeometryTypeFromGeojson(
      this.location?.geometry?.type ?? null
    );
    const LeafletClass = geometryType === "line" ? Polyline : Polygon;
    const tooltipHead = getTooltipHead(this.location, options);
    const tooltipValue = getTooltipValue(this.location, options);

    const onClick = (_) => {
      const identifier = this?.location?.identifier ?? null;
      if (!Object.is(null, identifier)) {
        dispatch({
          type: SELECTIONS_ACTIONS.ADD_LOCATION_TO_FILTERED_LOCATIONS,
          payload: this.location,
        });
      }
    };

    return (
      tooltipValue && (
        <LeafletClass
          key={key}
          positions={positions}
          pathOptions={pathOptions}
          eventHandlers={{ click: onClick }}
        >
          {pathOptions.color !== "transparent" && (
            <>
              {Object.is(null, tooltipValue) ? (
                <Tooltip sticky>{tooltipHead}</Tooltip>
              ) : (
                <Tooltip sticky>
                  {tooltipHead}
                  <br />
                  {tooltipValue}
                </Tooltip>
              )}
            </>
          )}
        </LeafletClass>
      )
    );
  }
}

function getPathOptions(value, thematicOptions) {
  /*
  Returns the pathOptions from with @param thematicOptions,
  as originally derived from getThematicOptions(),
  with appropriate color or opacity based on @param value as a numeric
  */
  const pathOptions = { ...(thematicOptions?.pathOptions ?? {}) };
  if (thematicOptions && thematicOptions.hasOwnProperty("range")) {
    for (let i in thematicOptions.range) {
      if (value <= thematicOptions.range[i].lessthan) {
        pathOptions[thematicOptions.stylename] = thematicOptions.range[i].style;
        break;
      }
    }
  }
  return pathOptions;
}

function getTooltipHead(location, options) {
  /*
  Returns a string containing the description of the object, eg a region's name/identity, or null, where:
  @param location is BaseRenderer's this.location
  */
  if (!location || !location.hasOwnProperty("identifier")) return ""; // Always an identifier

  return (
    (location.hasOwnProperty("resolution") && options.showResolution
      ? location.resolution === "LA"
        ? "District: "
        : `${location.resolution}: `
      : location.hasOwnProperty("name")
      ? ""
      : options.idPrefix
      ? `${options.idPrefix}: `
      : "") +
    (location.hasOwnProperty("name")
      ? `${location.name} (${location.identifier})`
      : location.identifier)
  );
}

function getTooltipValue(location, options) {
  /*
  Returns a string describing the value shown by the object, or null, where:
  @param location is BaseRenderer's this.location
  @param options are those originally derived from getThematicOptions()
  */
  if (
    !options ||
    !location ||
    !options.hasOwnProperty("valueField") ||
    !location.hasOwnProperty(options.valueField)
  )
    return null;

  const valueData = getEnumNameAndDataMatchingValue(
    options.valueField,
    ValueField
  );
  if (valueData === undefined) return null;

  return (
    (Object.is(null, options.displayName) ? "" : `${options.displayName}: `) +
    (Object.is(null, options.unitPrefix) ? "" : `${options.unitPrefix} `) +
    getDisplayNumeric(location[options.valueField], 4) +
    (Object.is(null, options.unitSuffix) ? "" : ` ${options.unitSuffix}`) +
    (Object.is(null, valueData[1]?.suffixText)
      ? ""
      : ` ${valueData[1].suffixText}`)
  );
}

class ObjectRenderer {
  constructor(dispatch, thematicOptions) {
    this.dispatch = dispatch;
    this.thematicOptions = thematicOptions;
  }
  render(locations) {
    return locations.map((location) => {
      const { dispatch, thematicOptions } = this;
      const renderer = getRenderer(location);
      return renderer.render(dispatch, thematicOptions);
    });
  }
}

function getRenderer(location) {
  const geometryType = getGeometryTypeFromGeojson(
    location?.geometry?.type ?? null
  );

  switch (geometryType) {
    case "point":
      return new PointRenderer(location);
    case "polygon":
    case "line":
      return new PolygonAndLineRenderer(location);
    default:
      return new NullRenderer(location);
  }
}

function UpdateMapCentre(props) {
  const map = useMap();
  if (props.previous !== props.location) map.panTo(props.location);
  return null;
}

export { ObjectRenderer, RendererError, UpdateMapCentre };
