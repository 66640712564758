import { combineReducers } from "utils";

import {
  initialState as interactionsInitialState,
  reducer as interactionsReducer,
  ACTIONS as INTERACTIONS_ACTIONS,
} from "./interactions";
import {
  initialState as globalsInitialState,
  reducer as globalsReducer,
  ACTIONS as GLOBALS_ACTIONS,
} from "./globals";
import {
  initialState as locationsInitialState,
  reducer as locationsReducer,
  ACTIONS as LOCATIONS_ACTIONS,
} from "./locations";
import {
  initialState as selectionsInitialState,
  reducer as selectionsReducer,
  ACTIONS as SELECTIONS_ACTIONS,
} from "./selections";
import {
  initialState as themesInitialState,
  reducer as themesReducer,
  ACTIONS as THEMES_ACTIONS,
} from "./themes";

const reducer = combineReducers({
  selections: selectionsReducer,
  locations: locationsReducer,
  interactions: interactionsReducer,
  themes: themesReducer,
  globals: globalsReducer
});

const initialState = {
  selections: selectionsInitialState,
  locations: locationsInitialState,
  interactions: interactionsInitialState,
  themes: themesInitialState,
  globals: globalsInitialState,
};

const ACTIONS = {
  selections: SELECTIONS_ACTIONS,
  locations: LOCATIONS_ACTIONS,
  interactions: INTERACTIONS_ACTIONS,
  themes: THEMES_ACTIONS,
  globals: GLOBALS_ACTIONS,
}

export {
  reducer,
  initialState,
  SELECTIONS_ACTIONS,
  LOCATIONS_ACTIONS,
  INTERACTIONS_ACTIONS,
  THEMES_ACTIONS,
  GLOBALS_ACTIONS,
  ACTIONS
};
