import parse from "html-react-parser";

import { getInfo } from "./data";

export default function Introduction(props) {
  /*
  Specific introduction information to render in the sidebar when no output is selected
  */
  const selectedInfo = getInfo(props.title);
  if (!selectedInfo) return null;

  return (
    <>
      <div className="info-area">
        {selectedInfo?.content ? parse(selectedInfo.content) : null}
      </div>
    </>
  );
}
