import { PAGES } from "pages/config";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import Button from "../Button";
import LateralNavbar from "./LateralNavbar";
import Logo from "../LogoComponent";
import "./NavbarStyle.css"

const StyledNavbar = styled.nav`
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0px;
  background-color: #f8f9fa; // Example background color, adjust as needed
`;

export default function Navbar({ isDefault }) {
  const location = useLocation()
  const [isClicked, setIsClicked] = useState(false);
  const [activeLink, setActiveLink] = useState("");
  const [sideNavOpen, setSideNavOpen] = useState("sideNavbar-notShown");

  const updateMenu = () => {
    if (!isClicked) {
      setSideNavOpen("sideNavbar-shown");
    } else {
      setSideNavOpen("sideNavbar-notShown");
    }
    setIsClicked(!isClicked);
  };

  const handleClick = () => {
    setSideNavOpen("sideNavbar-notShown");
    setIsClicked(!isClicked);
  };

  useEffect(() => {
    setActiveLink(location.pathname)
    if (sideNavOpen === "sideNavbar-shown") updateMenu();
  }, [location]);

  return (
    <>
      <StyledNavbar className="navbar">
        <LateralNavbar className={sideNavOpen} handleClick={handleClick} />
        <Link className={activeLink==="/" ? "ActiveNavButton" : "NavButton"} to={"/"}>
          Home
        </Link>
        {PAGES.map((page) => (
          <Link key={page.stbTag} id={page.stbTag} className={activeLink===page.path ? `ActiveNavButton` : "NavButton"} to={page.path}>
            {page.name}
          </Link>
        ))}
        {!isDefault ? <Logo className="logoNav" /> : null}
        <Button
          className="navbarMobile"
          src="/img/burgerIcon.png"
          alt="Burger Button Navbar"
          onClick={updateMenu}
        />
      </StyledNavbar>
      <div className="empty-blank-nav"></div>
    </>
  );
}
