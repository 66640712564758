import React from "react";

import { Route, Routes } from "react-router-dom";
import Visualiser from "pages/Visualiser";
import Landing from "pages/Landing";
import { PAGES } from "pages/config";

function App() {
  const routeComponents = PAGES.map(({stbTag, path}, index) => (
    <Route key={stbTag} exact path={path}  element={<Visualiser apiPostFix={stbTag} index={index} />} />
  ));
  
  return(
    <Routes>
      <Route exact path='/' element={<Landing />} />
      {routeComponents}         
  </Routes>
  );
}

export default App;
