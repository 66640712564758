import React from "react";

import objectPath from "object-path";

import { Typeahead } from 'react-bootstrap-typeahead';
import { VisualiserContext } from "../../context";
import 'react-bootstrap-typeahead/css/Typeahead.css';
// For Typeahead setup see: https://github.com/ericgio/react-bootstrap-typeahead

function BaseAutoSelector({
  id,
  options,
  action,
  aliaser = null,
  setter = null,
  accessor = null,
  clearKeyValue = null,
  showClearButton = true,
  placeholder = "",
  clearAfterChange = false,
}) {
  const { state, dispatch } = React.useContext(VisualiserContext);
  const _setter = setter?.(dispatch, action) ?? defaultSetter(dispatch, action);
  const _state = objectPath.get(state, accessor);
  const _aliaser = aliaser ?? defaultAliaser(options);

  const typeaheadRef = React.useRef(null);

  return (
    <Typeahead
      ref={typeaheadRef}  
      clearButton={showClearButton}
      defaultSelected={_aliaser.filter(obj => obj.id === _state)}
      id={id}
      onChange={(selected) => { // selected is Typeahead props ref
        _setter(selected.length > 0 ? selected[0].id : (
          typeof clearKeyValue === "string" ? clearKeyValue : null
        ));
        if(clearAfterChange === true) {typeaheadRef.current.clear();}
      }}
      options={_aliaser}
      placeholder={placeholder}
    />
  );
}

function defaultAliaser(options) {
  return options.map(({ value, text }) => ({ 
    "id": value,
    "label": text
  }));
}
function defaultSetter(dispatch, action) {
  return (value) => {
    dispatch({ type: action, payload: value });
  };
}
export default BaseAutoSelector;
