import { Link, useLocation } from "react-router-dom";
import { PAGES } from "pages/config";
import { useEffect, useState } from "react";

export default function LateralNavbar(props) {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("");

  useEffect(() => {
    setActiveLink(location.pathname)
  }, [location])

  return (
    <div className={props.className}>
      <Link className={activeLink==="/" ? "ActiveLatButton" : "LatButton"} to={"/"} onClick={setActiveLink}>
        Home
      </Link>
      {PAGES.map((page) => (
        <Link key={page.stbTag} id={page.stbTag} className={activeLink===page.path ? "ActiveLatButton" : "LatButton"} to={page.path}>
          {page.name}
        </Link>
      ))}
    </div>
  );
}
