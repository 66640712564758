import React from "react";

import { SELECTIONS_ACTIONS } from "../reducers";
import { getGeometryTypeFromGeojson } from "utils/geometry";

function useOnOutputChange(state, dispatch) {
    /*
    Runs immediately after a state.selections.output change
    */
    const output = state?.selections?.output;
    const outputOptions = state?.selections?.outputOptions?.filter(
        (option) => option.optionName === output)?.[0];
    const valueTypes = outputOptions?.valueTypes ?? null;
    const fixedSelectors = outputOptions?.fixedSelectors ?? null;
    const selectedValue = state?.selections?.valueField ?? null;

    React.useEffect(() => {
        dispatch({ type: SELECTIONS_ACTIONS.UNPAUSE_MAP_RENDER })
    }, [output, dispatch]);  // Prevents map re-render before output-specific locations arrive

    React.useEffect(() => {
        if ((Object.is(null, selectedValue)) || (Object.is(null, valueTypes)) 
                || !valueTypes.includes(selectedValue)) {
            dispatch({ type: SELECTIONS_ACTIONS.RESET_VALUE_FIELD })
        }
    }, [dispatch, valueTypes, selectedValue]);

    React.useEffect(() => {
        // Reset choices that are no longer choices 
        if (!(Object.is(null, fixedSelectors))) {
            const reset_fixed = {
                "resolution": SELECTIONS_ACTIONS.RESET_RESOLUTION,
                "year": SELECTIONS_ACTIONS.RESET_YEAR,
                "travel_scenario": SELECTIONS_ACTIONS.RESET_TRAVEL_SCENARIO,
                "behavioural_scenario": SELECTIONS_ACTIONS.RESET_BEHAVIOURAL_SCENARIO
            };
            Object.entries(reset_fixed).forEach(([fixed, action]) => {
                if (!(fixedSelectors.includes(fixed))) dispatch({ type: action });
            });
        }
    }, [dispatch, fixedSelectors]); 
}

function useOnLocationOrFilteredLocationChange(state, dispatch) {
    /*
    Filters filteredLocations to ensure locations are still within locations after changes to
    - locations (manages to changes to reference dataset)
    - filteredLocations (manages invalid additions)
    
    Polygon (meaning LA or MSOA) have a special dispensation to support pre-existing filtering:
    Filtering by district identifier is maintained for an otherwise MSOA filter,
    based on globals.laMsoaMapping
    */
    const filteredLocations = state?.selections?.filteredLocations;
    const currentLocations = state?.locations?.locations;
    const laMsoaMapping = state?.globals?.laMsoaMapping;

    React.useEffect(() => {
        if (!(Object.is(null, filteredLocations)) && !(Object.is(null, currentLocations)) 
                && currentLocations.length > 0) {
            const geometryType = getGeometryTypeFromGeojson(currentLocations?.[0]?.geometry?.type);
            Object.entries(filteredLocations).forEach(([filteredKey]) => {
                const currentByFiltered = currentLocations.filter(location => {
                    return location.identifier === filteredKey;
                })
                if (currentByFiltered === undefined || currentByFiltered.length === 0) {
                    if (geometryType === "polygon" && !(Object.is(null, laMsoaMapping)) 
                            && laMsoaMapping.hasOwnProperty(filteredKey)) {
                        for (const childKey of laMsoaMapping[filteredKey]) {
                            if (currentLocations.filter(location => {
                                return location.identifier === childKey;
                            })) return;  // Valid child
                        }
                    }
                    // Else remove
                    dispatch({ 
                        type: SELECTIONS_ACTIONS.REMOVE_LOCATION_FROM_FILTERED_LOCATIONS,
                        payload: filteredKey
                    })
                }
            }) 
        }
    }, [dispatch, filteredLocations, currentLocations, laMsoaMapping]);
}

export { useOnOutputChange, useOnLocationOrFilteredLocationChange }
