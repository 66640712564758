import React from "react";

import objectPath from "object-path";

import RangeSlider from "react-bootstrap-range-slider";
import { VisualiserContext } from "pages/Visualiser/context";

/**
 * BaseSlider component allows users to select a value from a predefined list.
 * It uses a slider interface where each slider position corresponds to an index in the options array.
 *
 * @param {Object} props - The props for the component.
 * @param {Array} props.options - An array of values that the slider should allow the user to select from.
 * @param {Function} props.action - A reducer action reference, e.g., SELECTIONS_ACTIONS.SET_YEAR.
 * @param {String} props.accessor - A string path below state to the variable, e.g., "selections.year".
 * @returns {React.Component} The BaseSlider component.
 */
function BaseSlider({ options, action, accessor }) {
  const { state, dispatch } = React.useContext(VisualiserContext);
  const index = options.indexOf(objectPath.get(state, accessor));
  const maxIndex = options.length - 1;

  const onChange = (e) => {
    const index = Number(e.target.value);
    const value = options[index];
    dispatch({ type: action, payload: value });
  };

  if (Object.is(null, accessor)) return null;

  return (
    <>
      <RangeSlider
        value={index}
        onChange={onChange}
        min={0}
        max={maxIndex}
        step={1}
        tooltipLabel={(index) => options[index]}
      />
    </>
  );
}

export default BaseSlider;