import React from "react";

import styled from "styled-components";

import { VisualiserContext } from "pages/Visualiser/context";
import ModelSelector from "./ModelSelector";
import OutputSelector from "./OutputSelector";
import LocationFilterer from "./LocationFilterer";
import FixedSelectors from "./FixedSelectors";
import DynamicSelectors from "./DynamicSelectors";
import DownloadResults from "./DownloadResults";
import ValueMode from "./ValueMode";
import ShadingThemeSelector from "./ShadingThemeSelector";
import Warning from "../Informational/Warning"

const OuterWrapper = styled.div`
  flex-grow: 2;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0.5rem;
`;

export default function Selectors() {
  const { state } = React.useContext(VisualiserContext);
  const { output } = state?.selections ?? {};

  return (
    <OuterWrapper>
      <Wrapper>
        <ModelSelector />
        <OutputSelector />
        <Warning />
        {output ? <FixedSelectors /> : null}
        {output ? <LocationFilterer /> : null}
        {output ? <h3 className="h5 text-center">Display</h3> : null}
        {output ? <DynamicSelectors /> : null}
        {output ? <ValueMode /> : null}
        {output ? <ShadingThemeSelector /> : null}
        {output ? <DownloadResults /> : null}
      </Wrapper>
    </OuterWrapper>
  );
}
