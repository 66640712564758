import { useContext } from "react";

import { VisualiserContext } from "pages/Visualiser/context";
import { INTERACTIONS_ACTIONS } from "pages/Visualiser/reducers";
import BaseAutoSelector from "../Selectors/BaseAutoSelector";
import { getInfoOptions } from "./data";

export default function InfoSelector() {
  const location = useContext(VisualiserContext)
  const name = "info";
  const accessor = "interactions.info";
  const action = INTERACTIONS_ACTIONS.SET_INFO;
  const options = getInfoOptions(location.apiPostFix);
  options.unshift({ text: "", value: null });

  return (
    <BaseAutoSelector
      key={name}
      id={name}
      options={options}
      action={action}
      accessor={accessor}
      clearKeyValue={null}
      showClearButton={false}
      placeholder="Get help/explanation (type term)"
      clearAfterChange={true}
    />
  );
}
