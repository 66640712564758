import React from "react";

import camelCase from "camelcase";

import { api } from "services"
import { GLOBALS_ACTIONS, LOCATIONS_ACTIONS } from "../reducers";
import { ValueField } from "utils/enums";


export default function useGetOutputLocationsData(state, dispatch,apiPostFix) {
    /*
      Fetches the regions data from the API if the required selection options are
      set.
      */

    const { selections } = state;
    const { model, resolution, travelScenario, behaviouralScenario,  output,
            outputOptions } = selections ?? {};

    React.useEffect(() => {

        const addRegionNameAndGeometry = (regionsMapper) => {
            return (region) => {
                const updatedRegion = { ...region };
                const regionData = regionsMapper[region?.identifier];
                const name = regionData?.name;
                const geometry = regionData?.geometry;
                if (!Object.is(undefined, name)) {
                    updatedRegion.name = name;
                }
                if (Object.is(null, region?.geometry) && geometry) {
                    updatedRegion.geometry = { ...geometry };
                }
                if (region?.resolution === "MSOA" &&
                    !Object.is(undefined, regionData?.parent_la)) {
                    updatedRegion.parent_la = regionData.parent_la;
                }
                return updatedRegion;
            };
        }

        if (!Object.is(null, output)) {
            const geometryType = outputOptions?.filter(
                (option) => option.optionName === output)?.[0]?.geometryType ?? null;
            const psudeoResolution = definePsudeoResolution(geometryType, resolution);
            dispatch({ type: GLOBALS_ACTIONS.SET_IS_LOADING });
            dispatch({ type: LOCATIONS_ACTIONS.CLEAR_LOCATIONS });
            api.geodata
                .getAllRegionsForResolution(psudeoResolution, apiPostFix)
                .then((regionsMapper) => {
                    api.results
                        .getModelOutputs(apiPostFix,output, {
                            model,
                            resolution,
                            travelScenario,
                            behaviouralScenario,
                            includeGeometry: ((geometryType === "point") ? true : false),  // Points not in geodata
                        })
                        .then((result) => {
                            // Filterables cannot be determined elsewhere because output can change during callback 
                            // and thus create an infinite recursive loop
                            const filterables = outputOptions?.filter(
                                (option) => option.optionName === output)?.[0]?.filterables ?? [];
                            const filterableNames = filterables.map((filterable) => filterable.filterableDataName);
                            const unpackedResult = unpackResult(result, filterableNames);
                            const locations = unpackedResult.map(addRegionNameAndGeometry(regionsMapper));
                            dispatch({ type: LOCATIONS_ACTIONS.SET_LOCATIONS, payload: locations });
                        })
                        .catch(() => { 
                            alert("Required data could not be obtained. Please try reloading the page.");
                        })
                        .finally(() => {
                            dispatch({ type: GLOBALS_ACTIONS.SET_LOADING_FINISHED });
                        })
                });
        }
    }, [dispatch, output, travelScenario, behaviouralScenario, resolution, model, outputOptions,apiPostFix]);
}


function definePsudeoResolution(geometryType, resolution) {
    /*
    Defines supported psudeoResolutions
    Note that bespoke supporting functions must exist for each in 
    services.api.Geodata getAllRegionsForResolution()
    */
    switch(geometryType) {
        case "line":
            return "ROAD";
        default:
            switch(resolution) {
                case "LA":
                    return "LA";
                case "MSOA":
                    return "MSOA";
                default:
                    return null;
            }
    }
}

function unpackResult(result, filterableNames) {
    /*
    Converts v2+ API nested structure in @param result dictionary,
    into a tabular structure consisting complete lines of data in an array,
    where @param filterableNames is an array of filterableDataName,
    ordered as they occur in the v2+ data structure
    */
    if (Array.isArray(result)) return result;  // No need to unpack, assumed v1-style

    const unpackedResult = [];
    
    if (result.constructor !== Object || result?.d !== filterableNames.length || 
        result?.z.constructor !== Object) return unpackedResult;  // Garbage data
    
    const currentCriteria = {
        travelScenario: result?.t ?? null,
        behaviourScenario: result?.b ?? null,
        resolution: result?.r ?? null,
    };

    const yearKey = "year";
    const treeNames = (Array.isArray(filterableNames)) ? [yearKey, ...filterableNames] : [yearKey];
    const treeStructure = [];
    Object.entries(treeNames).forEach(([_, name]) => {
        treeStructure.push(camelCase(name));
    });
    let treeDepth = 0;  // Tracks position within treeStructure

    const validValues = {};  // Allowed value inputKey: outputKey
    Object.entries(ValueField).forEach(([_, obj]) => {
        validValues[obj.apiKey] = obj.value;
    });

    Object.entries(result.z).forEach(([identifier, zone]) => {
        currentCriteria.identifier = identifier;
        currentCriteria.geometry = zone?.g ?? null;  // Only if queried with ?includeGeometry=true
        if (zone.hasOwnProperty("a")) currentCriteria.areaCode = zone.a;  // Point only
        // For points, X and Y are also provided by the backend, but not used, so ignored

        treeParser(zone?.y);
    });

    function treeParser(branch) {
        /*
        Walks through a multi-level dictionary-based tree of variable depth to extract values by criteria,
        updating currentCriteria accordingly, and write complete data lines into unpackedResult

        @param branch is a dictionary whose keys are value treeStructure[treeDepth]
        and whose values consist the next treeStructure,
        or valueField-like keys and their respective numerics if treeDepth >= treeStructure.length

        Note that this function implicitly inherits variables from parents,
        which avoids having to keep passing them all up and down
        */
        if (branch?.constructor !== Object) return null; // Bad structure

        if (treeDepth >= treeStructure.length) {
            /*
            Expects values in branch - if found add whole line to unpackedResult
            Note value data is not added to currentCriteria to avoid accidental duplication of values,
            which would occur if different parts of the result data offer different values (not expected)
            */
            const currentValues = {};
            Object.entries(validValues).forEach(([input, output]) => {
                if (branch.hasOwnProperty(input)) currentValues[output] = branch[input];
            });
            if (Object.keys(currentValues).length > 0)
                unpackedResult.push(Object.assign({}, currentCriteria, currentValues));
            return null;
        }

        Object.entries(branch).forEach(([parent, child]) => {
            currentCriteria[treeStructure[treeDepth]] = 
                (treeStructure[treeDepth] === yearKey) ? Number(parent) : parent;
                // Year key is uniquely converted to a number
            treeDepth = treeDepth + 1;
            treeParser(child);
            treeDepth = treeDepth - 1;
        });
    }

    return unpackedResult;
}
