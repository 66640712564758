import styled from "styled-components";
import Informational from "./Informational";
import Selectors from "./Selectors";
import TermsOfUse from "./TermsOfUse";
import React, { useContext } from "react";
import { VisualiserContext } from "./../context.js";
import { PAGES } from "pages/config";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: var(--bs-primary);
  position: relative;
  display: flex;
  flex-direction: column;
  border-right: 1px solid gray;
`;

const Upper = styled.div`
  align-content: flex-start;
  text-align: left;
`;

const Middle = styled.div`
  flex-grow: 20;
  align-content: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: left;
`;

const Lower = styled.div`
  align-content: flex-end;
  text-align: left;
`;

const TitleBlock = styled.div`
  text-align: left;
  padding: 0 0.5rem;
`;

export default function Sidebar() {
  const { index } = useContext(VisualiserContext); // Get apiPostfix from context
  const titleSrc = PAGES[index].title;
  const websiteLink = PAGES[index].website

  return (
    <Wrapper>
      <Upper>
        <Informational />
      </Upper>
      <Middle>
        <TitleBlock>
          <a
            href={websiteLink}
            title={titleSrc}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <h1 className="h4">{titleSrc}</h1>
          </a>
        </TitleBlock>
        <Selectors />
      </Middle>
      <Lower className="terms">
        <a
          href="https://transportforthenorth.com/major-roads-network/electric-vehicle-charging-infrastructure/"
          title="Transport for the North - Electric Vehicle Charging Infrastructure"
          target="_blank"
          rel="noopener noreferrer"
        >
          Read about method
        </a>{" "}
        &#183; <TermsOfUse /> &#183;{" "}
        <a
          href={"mailto:" + PAGES[index].contact}
          title="Contact the organisation"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact
        </a>
      </Lower>
    </Wrapper>
  );
}
