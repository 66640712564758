import BaseService from "./Base";

class ResultsService extends BaseService {
  constructor(options = {}) {
    super({ pathPrefix: "modelOutputs", ...options });
  }

  async getModelOutputs(
    stb,
    model_output,
    {
      model = null,
      resolution = null,
      year = null,
      travelScenario = null,
      behaviouralScenario = null,
      includeGeometry = null,
    }
  ) {
    if (Object.is(undefined, stb) || Object.is(undefined, model_output))
      throw new Error("STB and model_output must be specified");

    const options = {
      queryDict: {
        model,
        year,
        resolution,
        travelScenario,
        behaviouralScenario,
        includeGeometry,
      },
    };
    // Include stb in the URL path
    return await this.get(`${stb}/${model_output}`, options);
  }

  async getDownloadModelOutputs(
    stb,
    model_output,
    {
      model = null,
      resolution = null,
      year = null,
      travelScenario = null,
      behaviouralScenario = null,
      value = null,
    }
  ) {
    if (Object.is(undefined, stb) || Object.is(undefined, model_output))
      throw new Error("STB and model_output must be specified");

    const options = {
      queryParams: {
        model,
        year,
        resolution,
        travelScenario,
        behaviouralScenario,
        value,
      },
    };
    // Include stb in the URL path for download
    return await this.get(`${stb}/${model_output}/download`, options);
  }

  getDownloadModelOutputsLink(
    stb,
    model_output,
    {
      model = null,
      resolution = null,
      year = null,
      travelScenario = null,
      behaviouralScenario = null,
      outputSelections = null,
      identifier = null,
      value = null,
    }
  ) {
    if (Object.is(undefined, stb) || Object.is(undefined, model_output))
      throw new Error("STB and model_output must be specified");

    const queryDict = {
      model, resolution, year, travelScenario,
      behaviouralScenario, ...(outputSelections ?? {}),
      identifier, value
    };
    const query = this._buildQuery(queryDict);
    
    // Construct the download link including stb
    const subpath = this._buildUrl(`${stb}/${model_output}/download`);

    return `${subpath}?${query}`;
  }
}


export default new ResultsService();


