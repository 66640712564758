export default function getCssRule(ruleName){
    ruleName = ruleName.toLowerCase();
    var result = null
    var find = Array.prototype.find;
    find.call(document.styleSheets, styleSheet => {
        result = find.call(styleSheet.cssRules, cssRule =>{
            return cssRule instanceof CSSStyleRule && cssRule.selectorText.toLowerCase() === ruleName;
        })
        return result != null;
    })
    return result;
}