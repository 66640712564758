
import BaseService from "./Base";

class MetadataService extends BaseService {
  // Removed options from the constructor since the dynamic nature of STB makes a static pathPrefix less useful
  constructor() {
    super(); // Calling the parent constructor without pathPrefix
  }

  // Added stb parameter to getAllMetadata method
  async getAllMetadata(stb, options = {}) {
    if (!stb) throw new Error("STB must be specified");
    // Use the stb parameter to construct the path dynamically
    const path = `${stb}/metadata`;
    return await this.get(path, options);
  }

  // Added stb parameter to getMetadata method
  async getMetadata(stb, output = null, options = {}) {
    if (!stb) throw new Error("STB must be specified");
    if (Object.is(null, output)) return null;
    // Use both stb and output to construct the path dynamically
    const path = `${stb}/metadata/${output}`;
    return await this.get(path, options);
  }
}

export default new MetadataService();