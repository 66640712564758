import { createActions } from "utils";
import { getEnumNameAndDataMatchingValue, ModelField, ValueField } from "utils/enums";

import lodash from "lodash";


const ACTIONS = createActions("selections", {
  SET_RESOLUTION: "SET_RESOLUTION",
  RESET_RESOLUTION: "RESET_RESOLUTION",
  SET_YEAR: "SET_YEAR",
  RESET_YEAR: "RESET_YEAR",
  SET_TRAVEL_SCENARIO: "SET_TRAVEL_SCENARIO",
  RESET_TRAVEL_SCENARIO: "RESET_TRAVEL_SCENARIO",
  SET_BEHAVIOURAL_SCENARIO: "SET_BEHAVIOURAL_SCENARIO",
  RESET_BEHAVIOURAL_SCENARIO: "RESET_BEHAVIOURAL_SCENARIO",
  SET_VALUE_FIELD: "SET_VALUE_FIELD",
  RESET_VALUE_FIELD: "RESET_VALUE_FIELD",
  SET_MODEL: "SET_MODEL",
  RESET_MODEL: "RESET_MODEL",
  SET_OUTPUT: "SET_OUTPUT",
  SET_OUTPUT_OPTIONS: "SET_OUTPUT_OPTIONS",
  SET_OUTPUT_SELECTIONS: "SET_OUTPUT_SELECTIONS",
  CLEAR_OUTPUT_SELECTIONS: "CLEAR_OUTPUT_SELECTIONS",
  SET_FILTERED_LOCATIONS: "SET_FILTERED_LOCATIONS",
  ADD_LOCATION_TO_FILTERED_LOCATIONS: "ADD_LOCATION_TO_FILTERED_LOCATIONS",
  REMOVE_LOCATION_FROM_FILTERED_LOCATIONS: "REMOVE_LOCATION_FROM_FILTERED_LOCATIONS",
  CLEAR_FILTERED_LOCATIONS: "CLEAR_FILTERED_LOCATIONS",
  UPDATE_OUTPUT_SELECTIONS: "UPDATE_OUTPUT_SELECTIONS",
  UNPAUSE_MAP_RENDER: "UNPAUSE_MAP_RENDER",
});

const initialState = {  // Defines defaults for selections
  model: ModelField.DEFAULT.value,  // Model as value, not Enum, which is much easier to dispatch to the API
  resolution: "LA",
  year: 2023,
  travelScenario: "BAU",
  behaviouralScenario: "BASE",
  valueField: ValueField.Value.value,
  output: null,
  outputOptions: null,
  outputSelections: {},
  filteredLocations: null,
  pauseMapRender: false,  // Prevents changed output triggering map re-render before changed locations arrive
};

function reducer(state, action) {
  /*
  Processes one @param action to change a VisualiserContext state.selections
  @param state is what becomes state.selections
  @param action must contain .type and .payload, but certain actions also check for .metadata,
  which is a current copy of globals.outputsMetadata
  */
  switch (action.type) {
    case ACTIONS.SET_RESOLUTION:
      return {
        ...state,
        resolution: action?.payload ?? initialState.resolution,
      };
    case ACTIONS.RESET_RESOLUTION:
      return {
        ...state,
        resolution: initialState.resolution,
      };
    case ACTIONS.SET_YEAR:
      return {
        ...state,
        year: action?.payload ?? initialState.year,
      };
    case ACTIONS.RESET_YEAR:
      return {
        ...state,
        year: initialState.year,
      };
    case ACTIONS.SET_TRAVEL_SCENARIO:
      return {
        ...state,
        travelScenario: action?.payload ?? initialState.travelScenario,
      };
    case ACTIONS.RESET_TRAVEL_SCENARIO:
      return {
        ...state,
        travelScenario: initialState.travelScenario,
      };
    case ACTIONS.SET_BEHAVIOURAL_SCENARIO:
      return {
        ...state,
        behaviouralScenario:
          action?.payload ?? initialState.behaviouralScenario,
      };
    case ACTIONS.RESET_BEHAVIOURAL_SCENARIO:
      return {
        ...state,
        behaviouralScenario: initialState.behaviouralScenario,
      };
    case ACTIONS.SET_OUTPUT:
      return {
        ...state,
        output: action?.payload ?? initialState.selectedOutput,
        outputSelections: sanitizedOutputSelections(state, action?.payload, state?.outputOptions),
        pauseMapRender: (action?.payload === state?.output) ? false : true,
      };
    case ACTIONS.SET_OUTPUT_OPTIONS:
      // Expects action.payload to be global.outputsMetadata
      return {
        ...state,
        outputOptions:
          getOutputOptions(state, state?.model, action?.payload) ?? initialState.selectedOutputSelectOptions,
      };
    case ACTIONS.UPDATE_OUTPUT_SELECTIONS:
      // Called when (analysis) category changes, once per display filterable (for dynamic selectors),
      // with payload a dict of category: previous_value
      return {
        ...state,
        outputSelections: { ...state.outputSelections, ...action.payload },
        // Augments outputSelections so that any prior selection can be recalled
      };
    case ACTIONS.SET_FILTERED_LOCATIONS:
      let payload = action?.payload ?? null;
      if (!Object.is(null, payload)) payload = lodash.uniq(payload);
      return { ...state, filteredLocations: payload };
    case ACTIONS.CLEAR_FILTERED_LOCATIONS:
      return { ...state, filteredLocations: null };
    case ACTIONS.ADD_LOCATION_TO_FILTERED_LOCATIONS:
      const newIdentifier = action?.payload?.identifier ?? null;
      if (!(Object.is(null, newIdentifier)) && state.filteredLocations?.[newIdentifier]) {
        // Add an existing location to filteredLocations means remove it
        let revisedFilteredLocations = { ...state.filteredLocations };
        delete revisedFilteredLocations[newIdentifier];
        if (Object.keys(revisedFilteredLocations).length === 0) {
          return { ...state, filteredLocations: null };
        }
        return {
          ...state,
          filteredLocations: revisedFilteredLocations,
        }
      }
      return {
        ...state,
        filteredLocations: {
          ...state.filteredLocations,
          [newIdentifier]: action.payload,
        },
      };
    case ACTIONS.REMOVE_LOCATION_FROM_FILTERED_LOCATIONS:
      const removeIdentifier = action?.payload ?? null;
      if (
        Object.is(null, removeIdentifier) ||
        !state.filteredLocations?.[removeIdentifier]
      )
        return state;
      let newFilteredLocations = { ...state.filteredLocations };
      delete newFilteredLocations[removeIdentifier];
      if (Object.keys(newFilteredLocations).length === 0) {
        return { ...state, filteredLocations: null };
      }
      return {
        ...state,
        filteredLocations: newFilteredLocations,
      };
    case ACTIONS.SET_VALUE_FIELD:
      const value = action.payload;
      if (!getEnumNameAndDataMatchingValue(value, ValueField)) {
        return state;
      }
      return {
        ...state,
        valueField: value
      }
    case ACTIONS.RESET_VALUE_FIELD:
      return {
        ...state,
        valueField: initialState.valueField
      }
    case ACTIONS.SET_MODEL:
      // Also expects actions.metadata
      const model = action?.payload;
      if (!getEnumNameAndDataMatchingValue(model, ModelField)) { 
        return state;
      }
      const outputOptions = getOutputOptions(state, model, action?.metadata);
      return {
        ...state,
        model: model,
        outputOptions: outputOptions ?? initialState.selectedOutputSelectOptions,
        outputSelections: sanitizedOutputSelections(state, state?.output, outputOptions),
      }
    case ACTIONS.UNPAUSE_MAP_RENDER:
      return {
        ...state,
        pauseMapRender: false,
      };
    default:
      return state;
  }
}

function getOutputOptions(state, model, metadata) {
  /*
  Returns content for selections.outputOptions, where
  @param state is that of the reducer (what will be selections)
  @param model is the current model (what will be selections.model)
  @param metadata is globals.outputsMetadata (sent to the reducer as action.metadata)
  */
  const useModel = model ?? state?.model ?? initialState.model;
  const modelOptions = metadata?.[useModel] ?? {};
  const outputOptions = Object.entries(modelOptions).map(([key, val]) => {
    return {
      ...val,
      optionName: key,
    };
  });
  return outputOptions;
}

function sanitizedOutputSelections(state, output, outputOptions) {
  /*
  Ensures outputSelections contains valid option based on model's metadata:
  If key does not exist or key contains invalid option, add key with filterables default

  @param state is that of the reducer (what will be selections)
  @param output and outputOptions is the updated (specify these where changing)
  @return valid outputSelections
  */
  const useOutput = output ?? state?.output ??  initialState.selectedOutput;
  const useOutputOptions = outputOptions ?? state?.outputOptions ?? initialState.outputOptions;
  const outputSelections = state?.outputSelections ?? initialState.outputSelections;
  const outputMetadata = useOutputOptions?.filter(
    (option) => option.optionName === useOutput)?.[0] ?? {};
  if (outputMetadata.filterables) {
    outputMetadata.filterables.forEach(function (filterable) {
      if (filterable.filterableDataName && filterable.defaultOptionDataName && filterable.options) {
        if (!(outputSelections.hasOwnProperty(filterable.filterableDataName)) || 
          (filterable.options.filter(
            (option) => option.optionDataName === outputSelections[filterable.filterableDataName])
          ).length === 0) {  // No key, or key contains invalid option, so set with default
          outputSelections[filterable.filterableDataName] = filterable.defaultOptionDataName;
        }
      }
    });
  }
  return outputSelections;
}

export { ACTIONS, initialState, reducer };
