// Landing.jsx
import Footer from "components/Footer";
import Navbar from "components/Navbar/Navbar";
import parse from "html-react-parser";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { LANDING, PAGES } from "./config";

const LogoImage = styled.img`
  width: auto;
  max-width: 300px;
  max-height: 192px;
`;

function Landing() {
  useEffect(() => {
    const root = document.documentElement;
    root?.style.setProperty("--bs-body-font-family", LANDING.fontFamily);
  });

  const navigate = useNavigate();
  const onClick = (path) => {
    navigate(path);
  };
  return (
    <>
      <style>@import url({LANDING.fontUrl})</style>
      <title>STB EV Charging Infrastructure Framework</title>
      <Navbar isDefault={true} />
      <div className="landing">
        <div
          className="header-landing"
          placeholder="Background Image for the header"
        >
          <div className="container">
            <h1 className="header-title">{LANDING.title}</h1>
          </div>
        </div>
        <section className="introduction">
          <h2>Introduction</h2>
          <p className="container-intro">{parse(LANDING.introduction)}</p>
        </section>
        <section className="background">
          <h2>Background of the Framework</h2>
          <p className="container-bg">{parse(LANDING.background)}</p>
        </section>
        <h2>Click on an STB logo below to utilise the relevant visualiser.</h2>
        <ul className="company-list">
          {PAGES.map((page) => (
            <li key={page.stbTag} className="company">
              <LogoImage
                src={page.logo}
                alt={page.name}
                onClick={() => onClick(page.path)}
                className="company-logo"
              />
            </li>
          ))}
        </ul>
        <div className="contacts">
          <p>
            If you have any queries about data or the visualiser tool itself,
            please contact the relevant STB representative using the links below
            :
          </p>
          <ul className="contactsList">
          {PAGES.map((page) => (
            <a className="contact" href={"mailto:" + page.contact + "?subject=Question regarding EVCI Framework"} key={page.stbTag}>{page.name}</a>
          ))}
          </ul>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default Landing;
