function getDisplayNumeric(number, figures) {
  /*
  Returns @param number, to @param figures significant figures,
  as a formatted string for display to the user
  */

  function stripTrailingZeros(stringNumeric) {
    /*
    Returns @param stringNumeric less trailing decimal zeros,
    for example, 123.400 becomes 123.4
    */
    if(!stringNumeric.includes("."))
      return stringNumeric

    while (true) {
      if (stringNumeric.length < 1) return stringNumeric;
      let lastChr = stringNumeric.charAt(stringNumeric.length - 1);
      if (lastChr !== "0" && lastChr !== ".") return stringNumeric;
      if (lastChr === ".") return stringNumeric.slice(0, -1);
      stringNumeric = stringNumeric.slice(0, -1);
    }
  }

  if (number === undefined) return "";
  let numeric = Number(number);
  if (isNaN(numeric)) return number.toString();
  if (numeric === 0) return numeric.toString();
  if (numeric >= 10) {
    const rounding = Math.pow(10, Math.round(number).toString().length - figures);
    numeric = Math.round(number / rounding) * rounding;
    if (numeric >= 1000) return stripTrailingZeros(Math.round(numeric).toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","));
    if (numeric >= 100) return stripTrailingZeros(Math.round(numeric).toString());
    return stripTrailingZeros((Math.round(numeric * 10) / 10).toString());
  }
  return stripTrailingZeros(numeric.toPrecision(figures));
}

export { getDisplayNumeric };
