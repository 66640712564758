import React from "react";

import {
  reducer,
  initialState,
} from "../reducers";
import useOnAppStart from "./useOnAppStart"
import { useOnOutputChange, useOnLocationOrFilteredLocationChange } from "./useOnOutputChange"
import useGetOutputLocationsData from "./useGetOutputLocationData";

export default function useVisualiserState(apiPostFix, index) {
  /*
    Creates visualiser state to be used by the context provider.
    */
    // Add apiPostfix and index to initialState or handle it as needed
   
  
    const extendedInitialState = {
      ...initialState,
      apiPostFix,
      index, // This adds the apiPostFix and the index to the initial state
    };
  const [state, dispatch] = React.useReducer(reducer, extendedInitialState);
  const contextValue = React.useMemo(() => {
    return { state, dispatch,apiPostFix, index };
  }, [state, dispatch,apiPostFix, index]);

  useOnAppStart(dispatch,apiPostFix);
  useOnOutputChange(state, dispatch);
  useOnLocationOrFilteredLocationChange(state, dispatch);
  useGetOutputLocationsData(state, dispatch,apiPostFix);

  return contextValue;
}
