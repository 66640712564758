import { createActions } from "utils";

const ACTIONS = createActions("interactions", {
  SET_INFO: "SET_INFO",
  SET_ZOOM: "SET_ZOOM",
});

const initialState = {
  info: null,
  zoom: 8,  // Leaflet map zoom
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.SET_INFO:
      return { ...state, info: action?.payload ?? null };
    case ACTIONS.SET_ZOOM:
      return { ...state, zoom: action?.payload ?? null };
    default:
      return state;
  }
}

export { ACTIONS, initialState, reducer };
