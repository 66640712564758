import React, { useMemo } from "react";

import { getFilterableNames } from "pages/Visualiser/Map/filterer";
import { VisualiserContext } from "pages/Visualiser/context";
import api from "services/api";

export default function DownloadResults() {
  const { state, apiPostFix } = React.useContext(VisualiserContext);
  const { globals, selections } = state;
  const {
    model,
    year,
    travelScenario,
    behaviouralScenario,
    output,
    valueField,
  } = selections ?? {};
  let { resolution } = selections ?? {}; // Irrelevant for non-polygons, but harmless
  const visible = !Object.is(null, selections?.output);
  const filteredLocations = selections?.filteredLocations ?? [];
  const laMsoaMapping = globals?.laMsoaMapping ?? {};
  const identifiers = getIdentifiers(
    filteredLocations,
    resolution,
    laMsoaMapping
  );
  const outputSelections = getOutputSelections(selections);
  const downloadLink = useMemo(
    () =>
      api.results.getDownloadModelOutputsLink(apiPostFix, output, {
        model,
        year,
        resolution,
        travelScenario,
        behaviouralScenario,
        outputSelections: outputSelections,
        identifier: identifiers,
        value: valueField,
      }),
    [
      apiPostFix,
      behaviouralScenario,
      identifiers,
      model,
      output,
      outputSelections,
      resolution,
      travelScenario,
      valueField,
      year,
    ]
  );

  return visible ? (
    <div className="w-100">
      <h3 className="h5 text-left">Get data</h3>
      <a href={downloadLink} download className="btn btn-secondary w-100">
        Download results
      </a>
    </div>
  ) : null;
}

function getIdentifiers(
  filteredLocations = [],
  resolution = [],
  laMsoaMapping = {}
) {
  if (Object.is(null, filteredLocations)) {
    return [];
  }
  const filteredLocationIdentifiers = Object.values(filteredLocations).map(
    (location) => location.identifier
  );
  if (resolution !== "MSOA") {
    return filteredLocationIdentifiers;
  }
  const filteredLocationsChildren = Object.values(filteredLocations).map(
    (location) => {
      if (location?.resolution === "LA") {
        const identifier = location.identifier;
        return laMsoaMapping[identifier];
      } else {
        return location.identifier;
      }
    }
  );
  const result = filteredLocationsChildren.reduce(
    (previousValue, currentValue) => {
      if (Array.isArray(currentValue)) {
        currentValue.forEach((value) => previousValue.push(value));
      } else {
        previousValue.push(currentValue);
      }
      return previousValue;
    },
    []
  );
  return result;
}

function getOutputSelections(selections) {
  const fiterableNames = getFilterableNames(selections);
  const outputSelections = selections?.outputSelections ?? {};
  const usedOutputSelections = {};
  Object.entries(outputSelections).forEach(([key, value]) => {
    if (fiterableNames.includes(key)) usedOutputSelections[key] = value;
  });
  return usedOutputSelections;
}
