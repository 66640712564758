import styled from "styled-components"; // Ensure styled-components is imported
import { VisualiserContext } from "pages/Visualiser/context";
import { useContext } from "react";
import { PAGES } from "pages/config";

const LogoImage = styled.img`
  width: auto;
  max-width: 192px;
  max-height: 192px;
  position: absolute;
  right: 1%;
`;

export default function Logo() {
  const { index } = useContext(VisualiserContext); // Correctly use useContext
  const logoSrc = PAGES[index].logo; // Determine the logo source

  return <LogoImage src={`${process.env.PUBLIC_URL}${logoSrc}`} alt="Logo" />;
}
