import React from "react";

import { Typeahead } from "react-bootstrap-typeahead";
import { Badge, Button } from "react-bootstrap";

import { VisualiserContext } from "pages/Visualiser/context";
import { SELECTIONS_ACTIONS } from "../../reducers";
import styled from "styled-components";

const SelectionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

function LocationFilterer() {
  const { state, dispatch } = React.useContext(VisualiserContext);
  const ref = React.useRef(null);
  const filteredLocations = React.useMemo(
    () => state?.selections?.filteredLocations ?? {},
    [state?.selections?.filteredLocations]
  );
  const allLocations = state?.locations?.locations ?? [];
  const uniqueLocations = Object.values(
    Object.fromEntries(
      allLocations.map((location) => [location?.identifier, location])
    )
  );
  const options = uniqueLocations
    .map((location) => ({
      data: { ...location },
      label: location?.name ?? location?.identifier,
      id: location?.identifier,
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));
  const onSelectedLocation = React.useCallback(
    (selected) => {
      const selectedLocation = selected?.[0] ?? null;
      if (!Object.is(null, selectedLocation)) {
        if (Object.is(undefined, filteredLocations[selectedLocation])) {
          dispatch({
            type: SELECTIONS_ACTIONS.ADD_LOCATION_TO_FILTERED_LOCATIONS,
            payload: selectedLocation?.data,
          });
        }
        ref?.current?.clear?.();
      }
    },
    [dispatch, filteredLocations]
  );
  const onClearSelectedLocations = React.useCallback(() => {
    dispatch({
      type: SELECTIONS_ACTIONS.CLEAR_FILTERED_LOCATIONS,
    });
  }, [dispatch]);
  const createRemoveFilteredLocation = React.useCallback(
    (identifier) => {
      return () =>
        dispatch({
          type: SELECTIONS_ACTIONS.REMOVE_LOCATION_FROM_FILTERED_LOCATIONS,
          payload: identifier,
        });
    },
    [dispatch]
  );

  return (
    <div className="w-100">
      <h4 className="h6 text-left">Optional location filter</h4>
      <Typeahead
        className="w-100"
        id="Location Filterer"
        onChange={onSelectedLocation}
        options={options}
        ref={ref}
        style={{marginBottom: "1vh"}}
      />
      <Button
        className="w-100"
        onClick={onClearSelectedLocations}
        variant="secondary"
      >
        Clear filters
      </Button>
      <SelectionsWrapper>
        {Object.values(filteredLocations).map((location) => {
          return (
            <Badge
              onClick={createRemoveFilteredLocation(location?.identifier)}
              bg="secondary"
              pill
              key={location?.identifier}
              style={{ cursor: "pointer" }}
            >
              {location?.name ?? location?.identifier}
            </Badge>
          );
        })}
      </SelectionsWrapper>
    </div>
  );
}

export default LocationFilterer;
