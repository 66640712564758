import { createActions } from "utils";

const ACTIONS = createActions("themes", {
  SET_REGION_SHADING_THEME: "SET_REGION_SHADING_THEME",
});

const initialState = {
  regionShading: "blue_range",
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.SET_REGION_SHADING_THEME:
      return { ...state, regionShading: action?.payload ?? null };

    default:
      return state;
  }
}

export { ACTIONS, initialState, reducer };
