import Dropdown from "react-bootstrap/Dropdown";
import styled from "styled-components";

const ToggleBox = styled.div`
  color: var(--bs-secondary);
  background-color: var(--bs-primary);
  width: 100%;
  display: flex;
  border-radius: 0.5rem;
`;

const ToggleText = styled.p`
  display: inline-block;
  flex-grow: 1;
  text-align: center;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  height: 100%;
`;
export default function DropdownSelector({
  value = null,
  setter = () => {},
  options = [],
  defaultText = "Select option",
  defaultValue = null,
}) {
  return (
    <Dropdown className="w-100 rounded-border">
      <ToggleBox>
        <ToggleText>{Object.is(null, value) ? defaultText : value}</ToggleText>
        <Dropdown.Toggle split></Dropdown.Toggle>
      </ToggleBox>

      <Dropdown.Menu className="w-100">
        {[...options].map((option) => {
          return (
            <Dropdown.Item
              key={option.hasOwnProperty("value") ? option.value : option}
              onClick={() =>
                setter(option.hasOwnProperty("value") ? option.value : option)
              }
              selected={((option.hasOwnProperty("value") ? option.value : option
                ) === defaultValue) ? true : false}
            >
              {option?.text ?? option}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}
