import React from "react";

import { Button, Modal } from "react-bootstrap";
import parse from 'html-react-parser';

import { VisualiserContext } from "../../context";
import { getInfo } from "./data";
import { INTERACTIONS_ACTIONS } from "pages/Visualiser/reducers";

export default function Selected() {
  const { state, dispatch } = React.useContext(VisualiserContext);
  const { interactions } = state;
  const selectedInfo = getInfo(interactions?.info)

  const _close = () => {
    dispatch({ type: INTERACTIONS_ACTIONS.SET_INFO, payload: null });
  };

  if (!selectedInfo) return null;

  return (
    <>
        <Modal show={true}
            onHide={() => _close()}
            backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title className="h5">
                  {selectedInfo?.title ?? ""}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="info-area">
              {selectedInfo?.content ? parse(selectedInfo.content) : null}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => _close()}>Ok</Button>
            </Modal.Footer>
        </Modal>
    </>)
}
