import BaseService from "./Base";

const localStorageKeys = Object.freeze({
  laKey: "_allLas",
  getLaKey: (stb) => `_allLas_${stb}`,
  getMsoaKey: (stb) => `_allMsoas_${stb}`,
  laMsoaMappingKey: (stb) => `__laMsoaMapping_${stb}`,
  msoaKey: "_allMsoa",
  la_MsoaMappingKey: "_laMsoaMapping",
  roadKey: "_allRoads",
  versionKey: "_geometryIds"
});  // Browser LocalStorage key names

class BaseCache {
  /*
  Base storage cache, intended to allow other modes of storage,
  but in practice only ever used for localStorage,
  and thus includes some currently redundant boilerplate functions
  */
  async _getAllMsoas() {
    return new Promise((resolve, _) => {
      resolve({});
    });
  }
  async _getAllLas() {
    return new Promise((resolve, _) => {
      resolve({});
    });
  }
  async _getLaMsoaMapping() {
    return new Promise((resolve, _) => {
      resolve({});
    });
  }
  async _getAllRoads() {
    return new Promise((resolve, _) => {
      resolve({});
    });
  }

  async getAllMsoas(stb) {
    const result = await this._getAllMsoas(stb);
    if (!result) {
      return null;
    }
    return result;
  }
  async getAllLas(stb) {
    const result = await this._getAllLas(stb);
    if (!result) {
      return null;
    }
    return result;
  }
  async getLaMsoaMapping(stb) {
    const result = await this._getLaMsoaMapping(stb);
    if (!result) {
      return null;
    }
    return result;
  }
  async getAllRoads() {
    const result = await this._getAllRoads();
    if (!result) {
      return null;
    }
    return result;
  }
}

class LocalStorageMemoryCache extends BaseCache {
  /*
  Stores geodata and mappings in the client browser's localStorage.
  */

  constructor(stb) {
    super();
    const storedLas = localStorage.getItem(localStorageKeys.getLaKey(stb));
    const storedMsoas = localStorage.getItem(localStorageKeys.getMsoaKey(stb));
    const storedLaMsoaMapping = localStorage.getItem(localStorageKeys.laMsoaMappingKey(stb));
    const storedRoads = localStorage.getItem(localStorageKeys.roadKey);
  
    this._allLas = storedLas ? JSON.parse(storedLas) : null;
    this._allMsoas = storedMsoas ? JSON.parse(storedMsoas) : null;
    this._laMsoaMapping= storedLaMsoaMapping ? JSON.parse(storedLaMsoaMapping) : null;
    this._allRoads = storedRoads ? JSON.parse(storedRoads) : null;
  }

  async setAllLas(value, stb) {
    const stringified = JSON.stringify(value);
    const cacheKey = this._getCacheKey(localStorageKeys.getLaKey(stb));
    localStorage.setItem(cacheKey, stringified);
  }
  async setAllMsoas(value, stb) {
    const stringified = JSON.stringify(value);
    const cacheKey = this._getCacheKey(localStorageKeys.getMsoaKey(stb));
    localStorage.setItem(cacheKey, stringified);
  }
  async setLaMsoaMapping(value,stb) {
    const stringified = JSON.stringify(value);
    const cacheKey = this._getCacheKey(localStorageKeys.laMsoaMappingKey(stb));
    localStorage.setItem(cacheKey, stringified);
  }
  async setAllRoads(value) {
    const stringified = JSON.stringify(value);
    this._allRoads = value;
    localStorage.setItem(localStorageKeys.roadKey, stringified);
  }

  async _getAllLas(stb) {
    const storedLas = localStorage.getItem(this._getLaKey(stb));
    return storedLas ? JSON.parse(storedLas) : null;
  }

  async _getAllMsoas(stb) {
    const storedMsoas = localStorage.getItem(this._getMsoaKey(stb));
    return storedMsoas ? JSON.parse(storedMsoas) : null;
  }
  async _getLaMsoaMapping(stb) {
    const storedLaMsoaMapping = localStorage.getItem(this._getLaMsoaMapping(stb));
    return storedLaMsoaMapping ? JSON.parse(storedLaMsoaMapping) : null;
  }
  async _getAllRoads() {
    return this._allRoads;
  }
  
  _getCacheKey(key, stb) {
    return `${key}_${stb}`;
  }
  
  _getLaKey(stb) {
    return `${localStorageKeys.laKey}_${stb}`;
  }

  _getMsoaKey(stb) {
    return `${localStorageKeys.msoaKey}_${stb}`;
  }
  _getLaMsoaMapping(stb) {
    return `${localStorageKeys.la_MsoaMappingKey}_${stb}`;
  }
}

function getCache() {
  return new LocalStorageMemoryCache();
}

const cache = getCache();
/*
Cache persists to enable potentially updated values to be held in memory
  while they are asynchronously being written to localStorage
It is required by GeodataService functions, for which getCache() is actually evoked
As asynchronous, the truth of localStorage is unknown,
  so cannot be called direct during a GeodataService function

Function purgeCache() intentionally does not use this structure,
  to keep its logic synchronous and ensure it runs fully before GeodataService 
  functions assess whether data can be called from API or cache
*/

class GeodataService extends BaseService {
  constructor(options = {}) {
    super({ pathPrefix: 'geodata', ...options });
  }
  
  _filterDataByStb(data, stb) {
    return Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value.stb === stb)
    );
  }

  async getRegionByIdentifier(identifier, stb) {
    const allLas = await this.getAllLas(stb);
    const allMsoas = await this.getAllMsoas(stb);
    const allRoads = await this.getAllRoads();

    if (allLas[identifier]) return allLas[identifier];
    if (allMsoas[identifier]) return allMsoas[identifier];
    if (allRoads[identifier]) return allRoads[identifier];
    return null;
  }

  async getAllRegionsForResolution(psudeoResolution, stb) {
    switch (psudeoResolution) {
      case "MSOA":
        return await this.getAllMsoas(stb);
      case "LA":
        return await this.getAllLas(stb);
      case "ROAD":
        return await this.getAllRoads();
      default:
        return null;
    }
  }

  async getAllLas(stb) {
    let cached = await cache.getAllLas(stb);
    if (Object.is(null, cached)) {
      const options = {}; // Assuming the API supports filtering by stb
      const result = await this.get(`${stb}/la`, options);
      const mapper = Object.fromEntries(
        result.map((region) => [region.identifier, region])
      );
      cache?.setAllLas?.(mapper);
      cached = mapper;
    }
    return cached;
  }

  async getMsoaParent(identifier) {
    let msoas = await cache.getAllMsoas();
    if (Object.is(null, msoas))  // If no cache, call API
      msoas = await this.getAllMsoas();
    const msoa = msoas?.[identifier] ?? null;
    if (Object.is(null, msoa)) return null;
    const parentLa = msoa?.parent_la ?? null;
    return parentLa;
  }

  async getAllMsoas(stb) {
    let cached = await cache.getAllMsoas(stb);
    if (Object.is(null, cached)) {
      const options = {};
      const result = await this.get(`${stb}/msoa`, options);
      const mapper = Object.fromEntries(
        result.map((region) => [region.identifier, region])
      );
      cache?.setAllMsoas?.(mapper);
      cached = mapper;
    }
    return cached;
  }

  async getLaChildren(identifier) {
    const LaMsoaMapping = await this.getLaMsoaMapping();
    return LaMsoaMapping?.[identifier] ?? null;
  }

  async getLaMsoaMapping(stb) {
    let cached = await cache.getLaMsoaMapping(stb);
    if (Object.is(null, cached)) {
      const mapper = {}
      let allMsoas = await cache.getAllMsoas(stb);
      if (Object.is(null, allMsoas))  // If no cache, call API
        allMsoas = await this.getAllMsoas(stb);
      if (!(Object.is(null, allMsoas))) {
        Object.values(allMsoas).forEach(msoa => {
          const identifier = msoa?.identifier ?? null;
          const parentLa = msoa?.parent_la ?? null;
          if (Object.is(null, identifier) || Object.is(null, parentLa)) return;
          if (mapper[parentLa] === undefined) {
            mapper[parentLa] = [identifier];
          } else if (!(mapper[parentLa].includes(identifier))) {
            mapper[parentLa].push(identifier);
          }
        });
        cache?.setLaMsoaMapping?.(mapper);
        cached = mapper;
      }
    }
    return cached;
  }

  async getAllRoads() {
    let cached = await cache.getAllRoads();
    if (Object.is(null, cached)) {
      const options = {};
      const result = await this.get("road", options);
      const mapper = Object.fromEntries(
        result.map((region) => [region.identifier, region])
      );
      cache?.setAllRoads?.(mapper);
      cached = mapper;
    }
    return cached;
  }
}

function purgeCache(globalMetadata,stb) {
  /*
  Empties relevant geodata cache where geometryIds have changed
  and caches current geometryIds for future reference

  @param globalMetadata is an output from api.metadata.getAllMetadata

  localStorage changes here are not asynchronous because they must be completed
  before any GeodataService function is called,
  else localStorage may still reflect prior rather than intended state
  */
  const storedGeometryIdsStr = localStorage.getItem(localStorageKeys.versionKey);
  const storedGeometryIds = storedGeometryIdsStr ? JSON.parse(storedGeometryIdsStr) : {};
  const currentGeometryIds = getCurrentGeometryIds(globalMetadata);

  if (JSON.stringify(currentGeometryIds) !== JSON.stringify(storedGeometryIds)) {
    // Tests only for most likely sameness only, for speed
    // Changes only in ordering will pass the subsequent
    Object.entries(currentGeometryIds).forEach(([resolution, geometry_id]) => {
      if (!(storedGeometryIds.hasOwnProperty(resolution)) || 
          storedGeometryIds[resolution] !== geometry_id) {
        switch(resolution) {
          case "LA":
            localStorage.removeItem(localStorageKeys.getLaKey(stb));
            break;
          case "MSOA":
            localStorage.removeItem(localStorageKeys.getMsoaKey(stb));
            localStorage.removeItem(localStorageKeys.laMsoaMappingKey(stb));
            break;
          case "ROAD":
            localStorage.removeItem(localStorageKeys.roadKey);
            break;
          default:
            break;
        }
      }
    });
    localStorage.setItem(localStorageKeys.versionKey, JSON.stringify(currentGeometryIds));
  }
}

function getCurrentGeometryIds(globalMetadata) {
  /*
  Returns dictionary of geometryIds in @param globalMetadata,
  an output from api.metadata.getAllMetadata
  */
  const currentGeometryIds = {};

  findGeometryId(globalMetadata);
  
  function findGeometryId(branch) {
    if (!(Object.is(null, branch)) && branch.constructor === Object) {
      Object.entries(branch).forEach(([key, value]) => {
        if (key === "geometryId" && value.constructor === Object) {
          Object.entries(value).forEach(([resolution, geometry_id]) => {
            currentGeometryIds[resolution] = geometry_id;
            // Intentionally replaces where resolution is duplicated
            // all geometry_id for a given resolution will be identical
          });
        } else {
          findGeometryId(value);
        }
      });
    }
  }
  
  return currentGeometryIds;
}

export { GeodataService, purgeCache };
