import { createActions } from "utils";

const ACTIONS = createActions("globals", {
    SET_IS_LOADING: "SET_IS_LOADING",
    SET_LOADING_FINISHED: "SET_LOADING_FINISHED",
    SET_OUTPUTS_METADATA: "SET_OUTPUTS_METADATA",
    SET_LA_MSOA_MAPPING: "SET_LA_MSOA_MAPPING",
    SET_ALL_LAS: "SET_ALL_LAS",
    SET_ALL_ROADS: "SET_ALL_ROADS"
});

const initialState = {
    isLoading: false,
    outputsMetadata: null,
    laMsoaMapping: null,
    allLas: null,
    allRoads: null
};

function reducer(state, action) {
    switch (action.type) {
        case ACTIONS.SET_IS_LOADING:
            return { ...state, isLoading: true };
        case ACTIONS.SET_LOADING_FINISHED:
            return { ...state, isLoading: false };
        case ACTIONS.SET_OUTPUTS_METADATA:
            return { ...state, outputsMetadata: { ...action.payload } };
        case ACTIONS.SET_LA_MSOA_MAPPING:
            return { ...state, laMsoaMapping: { ...action.payload } };
        case ACTIONS.SET_ALL_LAS:
            return { ...state, allLas: { ...action.payload } }
        case ACTIONS.SET_ALL_ROADS:
            return { ...state, allRoads: { ...action.payload } }
        default:
            return state;
    }
}

export { ACTIONS, initialState, reducer };
