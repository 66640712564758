import React from "react";

import { VisualiserContext } from "../context";
import { getDisplayNumeric } from "utils/formatting";
import { getThematicOptions } from "utils/theming";
import { LocationFilterer } from "./filterer";
import { getEnumNameAndDataMatchingValue, ValueField } from "utils/enums";
import { getGeometryTypeFromGeojson } from "utils/geometry";

function Legend() {
  /*
  Renders thematic map legend component
  */
  const { state } = React.useContext(VisualiserContext);
  const { themes, selections, locations } = state;

  return React.useMemo(() => {
    if (selections?.pauseMapRender === true) return null;
    const locationsList = locations?.locations ?? [];
    if (locationsList.length === 0) return null;
    const output = selections?.output ?? null;
    const outputOptions = selections?.outputOptions?.filter(
      (option) => option.optionName === output)?.[0] ?? null;
    const hideValue = outputOptions?.dataType?.hidden ?? false;
    if (output === null || hideValue === true) return null;

    const geometryType = getGeometryTypeFromGeojson(locations?.locations?.[0]?.geometry?.type);
    if (geometryType !== outputOptions?.geometryType) return null;
      // No synergy between locations and metadata (yet)

    const thematic = themes?.regionShading ?? null;
    const valueField = selections?.valueField ?? null;
    const thematicFilterer = new LocationFilterer(selections);
    const thematicFilteredLocations = thematicFilterer.filter(locationsList);
    const thematicOptions = getThematicOptions(
      thematicFilteredLocations, thematic, valueField, geometryType, outputOptions);
    if (thematicOptions === null || !(thematicOptions.hasOwnProperty("range")) || 
      thematicOptions.range.length === 0) return null;

    const valueData = getEnumNameAndDataMatchingValue(valueField, ValueField);
    const valueSubText = valueData?.[1]?.displayText ?? "";
    const valueDisplayName = outputOptions?.valueDisplayName ?? null;

    const legendContent = [];
    let fromValue = thematicOptions.minValue;
    let color, opacity;
    for (let i in thematicOptions.range) {
      switch (thematicOptions.stylename) {
        case "color":
        case "fillColor":
          color = thematicOptions.range[i].style;
          opacity = thematicOptions.pathOptions?.fillOpacity ?? thematicOptions.pathOptions.opacity;
          break;
        case "opacity":
        case "fillOpacity":
          opacity = thematicOptions.range[i].style;
          color = thematicOptions.pathOptions?.fillColor ?? thematicOptions.pathOptions.color;
          break;
        default:  // No theme
          opacity = 1;
          color = "grey";
      }
      legendContent.push({
        box: {
          width: "18px",
          height: "18px",
          float: "left",
          marginRight: "8px",
          backgroundColor: color,
          opacity: opacity,
          borderRadius: "50%",
          border: "solid 0.5px",
        },
        text:
          getDisplayNumeric(fromValue, 2) + " - " +
            getDisplayNumeric(thematicOptions.range[i].lessthan, 2),
        // Math.round(fromValue) + " - " + Math.round(thematicOptions.range[i].lessthan),
      });
      fromValue = thematicOptions.range[i].lessthan;
    }

    return (
      <>
        <div className="legend-outer">
          <div className="legend-container">
            <div className="legend-inner">
              <h3 className="h6"
                key={"legend_units"}>{valueDisplayName}</h3>
              <ul key={"legend_items"}>
                {valueSubText !== "" ? 
                  <li>{valueSubText}</li> : null}
                {legendContent.map((line, index) => {
                  return (
                    <li key={"legend_items_" + index}>
                      <span style={line.box}></span>
                            {line.text}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  
  }, [themes, selections, locations]);

}

export default React.memo(Legend);
