import Dimmer from "components/Dimmer";
import Navbar from "components/Navbar/Navbar";
import Dashboard from "layouts/Dashboard";
import { PAGES } from "pages/config";
import { useEffect } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import getCssRule from "utils/getCss";
import Map from "./Map/Map";
import Sidebar from "./Sidebar/Sidebar";
import { VisualiserContext } from "./context";
import { useVisualiserState } from "./hooks";


export default function Visualiser({ apiPostFix, index }) {
  const contextValue = useVisualiserState(apiPostFix, index);
  const isLoading = contextValue.state.globals.isLoading;
  const {fontUrl, fontFamily} = PAGES[index];

  useEffect(() => {
    const root = document.documentElement;
    root?.style.setProperty("--bs-body-font-family", fontFamily)
    getCssRule(".legend-outer").style.fontFamily = fontFamily;
    getCssRule(".leaflet-control").style.fontFamily = fontFamily;
    getCssRule(".leaflet-control").style.fontSize = "13px";
  })

  return (
    <VisualiserContext.Provider value={contextValue}>
      <style>@import url({fontUrl})</style>
      <title>STB EV Charging Infrastructure Framework</title>
      {
        <Dashboard>
          <Navbar isDefault={false} />
          <Container fluid style={{height: "calc(100% - 75px)"}}>
            <Row className="h-100">
              <Col xl="2.5" lg="3" md="4" sm="5" className="h-100 p-0">
                <Sidebar />
              </Col>
              <Col xl="9.5" lg="9" md="8" sm="7" className="h-100 p-0">
                <Dimmer dimmed={isLoading} showLoader />
                <Map />
              </Col>
            </Row>
          </Container>
        </Dashboard>
      }
    </VisualiserContext.Provider>
  );
}
