import React from "react";

import { THEMES_ACTIONS } from "pages/Visualiser/reducers";
import themes from "themes.json";
import BaseSelector from "./BaseSelector";

export default function ShadingThemeSelector() {
  const name = "thematic";
  const accessor = "themes.regionShading";
  const action = THEMES_ACTIONS.SET_REGION_SHADING_THEME;
  const options = Object.entries(themes).map(([key, theme]) => {
    return { text: theme?.name ?? key, value: key };
  });
  return (
    <><h4 className="h6 text-center">Shading</h4>
    <BaseSelector
      key={name}
      options={options}
      action={action}
      accessor={accessor} /></>
  );
}
