import React, { useEffect, useState, useContext } from "react";
import colors from "assets/colors.scss";

import { VisualiserContext } from "pages/Visualiser/context";
export default function Warning() {
  /*
  Displays any warning information defined by the metadata of the current output choice
  */
  const { state } = React.useContext(VisualiserContext);
  const { apiPostFix } = useContext(VisualiserContext);
  const [bgColor, setBgColor] = useState();
  const [fontColor, setFontColor] = useState();
  const { output, outputOptions } = state?.selections ?? {};
  const warning =
    outputOptions?.filter((option) => option.optionName === output)?.[0]
      ?.warning ?? null;

  useEffect(() => {
    const colorToFind = apiPostFix + "Secondary";
    const fontColorToFind =apiPostFix + "PrimaryFontColor";
    const scssColors = Object.keys(colors);
    const scssValues = Object.values(colors);
    scssColors.forEach((color, index) => {
      if (color === colorToFind) {
        setBgColor(scssValues[index]);
      }
      if (color === fontColorToFind) {
        setFontColor(scssValues[index]);
      }
    });
  }, [apiPostFix]);

  if (warning === null || warning === "") return null;

  return (
    <>
      <div
        className="warning-area"
        style={{ backgroundColor: bgColor, color: fontColor }}
      >
        <b>Warning:</b> {warning}
      </div>
    </>
  );
}
