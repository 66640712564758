import React, { useEffect, useState, useContext } from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import { VisualiserContext } from "pages/Visualiser/context";
import { SELECTIONS_ACTIONS } from "pages/Visualiser/reducers";
import { ModelField } from "utils/enums";
import Introduction from "../Informational/Introduction";
import colors from "assets/colors.scss";

export default function ModelSelector() {
  const { state, dispatch, apiPostFix } = useContext(VisualiserContext);
  const { output } = state?.selections ?? {};
  const selectedModel = state?.selections?.model ?? ModelField.DEFAULT.value;
  const metadata = state?.globals?.outputsMetadata ?? {};

  // Assuming colors is an object with color names as keys and color values as values
  const primaryColor = colors[`${apiPostFix}Primary`] || "defaultColor";

  useEffect(() => {
    if (apiPostFix !== "tfn") {
        state.selections.model = ModelField.DEFAULT.value
    };
  },[apiPostFix])

  if (apiPostFix !== "tfn") {
    return null;
  }

  return (
    <>
      <h4 className="text-left h6">Vehicle Stock Projection</h4>
      {output ? null : <Introduction title="introduction" />}
      <ButtonGroup className="w-100">
        {Object.entries(ModelField).map(([key, model]) => {
          const onChangeHandler = createOnChangeHandler(dispatch, model.value, metadata);
          const checked = selectedModel === model.value;
          return (
            <ToggleButton
              key={key}
              id={`model_selection_${key.toLowerCase()}`}
              type="radio"
              checked={checked}
              variant={checked ? "secondary" : "primary"}
              onChange={onChangeHandler}
              style={{
                fontSize: "0.95rem",
                border: "1px solid black",
                backgroundColor: checked ? primaryColor : undefined,
              }}
              className="btn"
            >
              {model.displayText}
            </ToggleButton>
          );
        })}
      </ButtonGroup>
    </>
  );
}

function createOnChangeHandler(dispatch, model, metadata) {
  return (_) => {
    dispatch({
      type: SELECTIONS_ACTIONS.SET_MODEL,
      payload: model,
      metadata: metadata,
    });
  };
}