import React, { useEffect, useState } from "react";

import objectPath from "object-path";

import { VisualiserContext } from "pages/Visualiser/context";
import { SELECTIONS_ACTIONS } from "pages/Visualiser/reducers";
import BaseSelector from "./BaseSelector";
import BaseSlider from "./BaseSlider";

const potentialSelectors = [
  {
    id: "resolution", // As in metadata fixedSelectors
    name: "resolution", // As referenced here
    displayName: "Administrative boundaries",
    slider: null,
    options: [
      {
        value: "LA",
        text: "Local Authority District",
        specific: ["tfn", "mc", "tfse"],
      },
      {
        value: "MSOA",
        text: "Middle Layer Super Output Area",
        specific: ["tfn", "mc", "tfse"],
      },
    ],
    action: SELECTIONS_ACTIONS.SET_RESOLUTION,
    accessor: "selections.resolution",
  },
  {
    id: "travel_scenario",
    name: "travelScenario",
    displayName: "Travel scenario",
    slider: null,
    options: [
      {
        value: "AEV",
        text: "UK Gov. - Accelerated EV",
        specific: ["tfn", "mc", "tfse"],
      },
      {
        value: "BAU",
        text: "UK Gov. - Business as Usual",
        specific: ["tfn", "mc", "tfse"],
      },
      { value: "DD", text: "TfN - Digitally Distributed", specific: ["tfn"] },
      { value: "JAM", text: "TfN - Just About Managing", specific: ["tfn"] },
      { value: "PP", text: "TfN - Prioritised Places", specific: ["tfn"] },
      { value: "UZC", text: "TfN - Urban Zero Carbon", specific: ["tfn"] },
    ],
    action: SELECTIONS_ACTIONS.SET_TRAVEL_SCENARIO,
    accessor: "selections.travelScenario",
  },
  {
    id: "behavioural_scenario",
    name: "behaviouralScenario",
    displayName: "Behavioural scenario",
    slider: null,
    options: [
      { value: "BASE", text: "Baseline", specific: ["tfn", "mc", "tfse"] },
      {
        value: "DESTINATION",
        text: "Destination Focus",
        specific: ["tfn", "mc", "tfse"],
      },
    ],
    action: SELECTIONS_ACTIONS.SET_BEHAVIOURAL_SCENARIO,
    accessor: "selections.behaviouralScenario",
  },
  {
    id: "year",
    name: "year",
    displayName: "Year",
    slider: [2023, 2025, 2030, 2035, 2040, 2045, 2050],
    options: [], // Ignored if slider
    action: SELECTIONS_ACTIONS.SET_YEAR,
    accessor: "selections.year",
  },
];


export default function FixedSelectors() {
  const { state, apiPostFix } = React.useContext(VisualiserContext);
  const { selections } = state;
  const { output } = selections;
  const [config, setNewConfig] = useState(setConfig(selections, output, apiPostFix))
  
  
  useEffect(() => {
    setNewConfig(setConfig(selections, output, apiPostFix))
  }, [selections, output, apiPostFix])

  if (!config) return null;
  
  return (
    <div className="w-100">
      {config.map((config) => {
        const { slider, options, name, action, accessor, displayName } = config;
        if (!Object.is(null, slider)) {
          const value = objectPath.get(state, accessor);
          return (
            <React.Fragment key={name}>
              <h4 className="text-center h6">
                {displayName}: {value}
              </h4>
              <BaseSlider
                options={slider}
                action={action}
                accessor={accessor}
              />
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment key={name}>
              <h4 className="text-center h6">{displayName}</h4>
              <BaseSelector
                options={options}
                action={action}
                accessor={accessor}
              />
            </React.Fragment>
          );
        }
      })}
    </div>
  );
}

function setConfig(selections, output, apiPostFix) {
  const newPotentialSelectors = JSON.parse(JSON.stringify(potentialSelectors));
  const fixedSelectors =
    selections?.outputOptions?.filter(
      (option) => option.optionName === output
    )?.[0]?.fixedSelectors ?? [];
  const config = [];
  if (Array.isArray(fixedSelectors)) {
    fixedSelectors.forEach((value) => {
      const selectorConfig = Object.entries(newPotentialSelectors).filter(
        ([index, dict]) => {
          return dict.id === value;
        }
      );
      if (selectorConfig !== undefined) {
        if (selectorConfig[0][1].options.length === 0)
          config.push(selectorConfig[0][1]);
        else {
          const newOptions = selectorConfig[0][1].options.filter((option) => {
            return option.specific.includes(apiPostFix);
          });
          selectorConfig[0][1].options = newOptions;
          config.push(selectorConfig[0][1]);
        }
      }
    });
  }
  return config;
}