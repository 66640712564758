import React, { useEffect } from "react";

import { VisualiserContext } from "pages/Visualiser/context";
import { SELECTIONS_ACTIONS } from "pages/Visualiser/reducers";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import { getEnumNameAndDataMatchingValue, ValueField } from "utils/enums";

export default function ValueMode() {
    const { state, dispatch, apiPostFix } = React.useContext(VisualiserContext);
    const output = state?.selections?.output ?? null;
    const selectedValue = state?.selections?.valueField ?? ValueField.Value.value;
    const valueTypes = state?.selections?.outputOptions?.filter(
        (option) => option.optionName === output)?.[0]?.valueTypes ?? [ValueField.Value.value];

    if (valueTypes.length <= 1) return null;

    return (
        <><h4 className="h6 text-center">Value</h4>
        <ButtonGroup className="w-100">
            {valueTypes.map((key) => {
                const value = getEnumNameAndDataMatchingValue(key, ValueField)[1];
                const onChangeHandler = createOnChangeHandler(dispatch, key);
                const checked = selectedValue === key;
                return (
                    <ToggleButton key={key}
                        id={`value_selection_${key}`}
                        type="radio"
                        checked={checked}
                        variant={checked ? "secondary" : "primary"}
                        onChange={onChangeHandler}
                        style={{ fontSize: "0.95rem", border: "1px solid black" }}
                    >{value.displayText}
                    </ToggleButton>
                )
            })}
        </ButtonGroup></>
    )
}

function createOnChangeHandler(dispatch, value) {
    return (_) => {
        dispatch({
            type: SELECTIONS_ACTIONS.SET_VALUE_FIELD,
            payload: value
        })
    }
}
