import { createActions } from "utils";

const ACTIONS = createActions("locations", {
  SET_LOCATIONS: "SET_LOCATIONS",
  CLEAR_LOCATIONS: "CLEAR_LOCATIONS",
  APPEND_LOCATIONS: "APPEND_LOCATIONS",
});

const initialState = {
  locations: [],
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.SET_LOCATIONS:
      return { ...state, locations: action.payload };
    case ACTIONS.CLEAR_LOCATIONS:
      return { ...state, locations: [] };
    case ACTIONS.APPEND_LOCATIONS:
      return {
        ...state,
        locations: [...(state?.locations ?? []), ...action.payload],
      };
    default:
      return state;
  }
}

export { ACTIONS, initialState, reducer };
