import React from "react";

import { VisualiserContext } from "pages/Visualiser/context";
import { SELECTIONS_ACTIONS } from "pages/Visualiser/reducers";
import BaseSelector from "./BaseSelector";

export default function DynamicSelectors() {
  const { state } = React.useContext(VisualiserContext);
  const { output, outputOptions } = state?.selections ?? {};
  const outputOption =
    outputOptions.filter((option) => option.optionName === output)?.[0] ?? null;
  if (Object.is(null, outputOption)) return null;

  const filterables = outputOption?.filterables ?? [];
  const configs =
    filterables.map((filterable) => {
      const name = filterable?.filterableDataName;
      const defaultValue = filterable?.defaultOptionDataName;
      const displayName = filterable?.filterableDisplayName;
      const options = (filterable?.options ?? []).map((option) => {
        return {
          text: option?.optionDisplayName ?? "",
          value: option?.optionDataName ?? null,
        };  // Null option should never occur (should use default) or be needed (default is the initial)
      });
      const accessor = `selections.outputSelections.${name}`;
      return {
        defaultValue,
        name,
        displayName,
        options,
        accessor,
      };
    }) ?? [];

  const action = SELECTIONS_ACTIONS.UPDATE_OUTPUT_SELECTIONS;

  if (Object.is(null, configs) || (configs?.length ?? 0) === 0) return null;
  return (
    <>
      {configs.map((config) => {
        const { name, displayName, options, accessor, defaultValue } = config;
        const setter = (dispatch, action) => {
          return (value) => {
            dispatch({
              type: action,
              payload: {
                [name]: value,
              },
            });
          };
        };
        return (
          <React.Fragment key={name}>
            <h4 className="h6 text-center">{displayName}</h4>
            <BaseSelector
              defaultValue={defaultValue}
              options={options}
              action={action}
              accessor={accessor}
              setter={setter}
            />
          </React.Fragment>
        );
      })}
    </>
  );
}
