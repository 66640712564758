class EnumError extends Error {}

class BaseEnum {
    constructor(value, displayText="", suffixText="", apiKey="") {
        this._value = value;
        this._displayText = displayText;
        this._suffixText = suffixText;
        this._apiKey = apiKey;
    }

    get value() {
        return this._value;
    }
    get displayText() {
        return this._displayText;
    }
    get suffixText() {
        return this._suffixText;
    }
    get apiKey() {
        return this._apiKey;
    }
    
    set value(_) {
        throw new EnumError("Value cannot be modified")
    }
    set displayText(_) {
        throw new EnumError("DisplayText cannot be modified")
    }
    set suffixText(_) {
        throw new EnumError("SuffixText cannot be modified")
    }
    set apiKey(_) {
        throw new EnumError("ApiKey cannot be modified")
    }

    toString() {
        return this.value.toString();
    }
}

class ValueField extends BaseEnum {
    static Value = new ValueField("value", "Total", "", "v");
    static PerSqKm = new ValueField("valuePerSqKm", "Per square km", "per square km", "s");
    static PerKm = new ValueField("valuePerKm", "Per km", "per km", "k");
    static PerThousandVehicles = new ValueField("valuePerThousandVehicles", "Per thousand vehicles", "per 1000 vehicles", "t");
}

class ModelField extends BaseEnum {
    static DEFAULT = new ModelField("DEFAULT", "Default");
    static INCOME = new ModelField("INCOME", "Income");
}

function getEnumNameAndDataMatchingValue(value, Enum) {
    /*
    Returns [EnumName, Object] from a given Enum where value matches Enum.value
    */
    return Object.entries(Enum).find(([_, enumValue]) => {
      return enumValue.value === value;
    });
  }

export {
    getEnumNameAndDataMatchingValue, ValueField, ModelField
}
